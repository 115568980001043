import React, { useState,useEffect } from 'react';
import { Menu} from 'antd';
import './AppHeader.scss';
import { useLocation } from 'react-router-dom';
import { useNavigate  } from 'react-router-dom';
import UserService from '../../../services/UserService';
import { MenuOutlined } from '@ant-design/icons';

function HeaderMenus(props) {
    const [menus, setMenus] = useState([]);
    const location = useLocation();
    const [current, setCurrent] = useState('');
   
    let navigate = useNavigate();

    useEffect(()=>{
        if(props?.menus){
            setMenus(props?.menus);
        }
    },[props?.menus])

    useEffect(()=>{
        if(menus.length>0){
            setMenus(menus);
            setCurrent(location?.pathname.replace("/",""));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[menus])

    const onClick = ({key}) => {
        if(key==="logout"){
            UserService.doLogout()
        }else if(key.includes(['https'])){
            window.open(key,'_blank');
        }else{
            if(key?.split()?.pop() !== "#"){
                setCurrent(key);
                navigate('/'+key);
            }
        }
    };

    return (
        <React.Fragment>
            <Menu 
                theme='dark'
                className="cu-bg-none bg-none font-weight-bold cu-header-menus"
                onClick={onClick} 
                selectedKeys={[current]} 
                // mode="horizontal" 
                mode="horizontal" 
                items={menus}
                overflowedIndicator={<MenuOutlined />}

            />
        </React.Fragment>
    );
}

export default HeaderMenus;