import React, { useEffect, useState } from "react";
import useDynamicNavigate from "../../../../utils/navigateUtils";
import { getMenuPortfolio } from "../../../../utils/settingCommon";
import { removeEmptyArrObjOrval } from "../../../../utils/Common";
import PortfolioSettingServices from "../../../../services/services/PortfolioSettingsService";
import { Button, Form, Select, Space } from "antd";
import { InfoOutlined, PlusOutlined } from "@ant-design/icons";
import PortfolioListModal from "../../common/PortfolioListModal";
import UpdateGlobalState from "../../common/UpdateGlobalState";
import TextWithTooltip from "../../../../utils/TextWithTooltip";

const AppsDropdown = ({index,name,title,form,...props}) => {
    const {state,updateLocale} = UpdateGlobalState();
    const navigateTo = useDynamicNavigate();
    const [loading,setLoading] = useState(false);
    const [listOptions,setListOptions] = useState([]);
    const [options,setOptions] = useState([]);
    const [portfolioData,setPortfolioData] = useState(null);
    const [filter,setFilter] = useState({});
    const [page,setPage] = useState(0);
    const [selectedApp,setSelectedApp] = useState([]);
    const [openModuleList,setOpenModuleList] = useState(false);

    useEffect(()=>{
        setPortfolioData(getMenuPortfolio({routeModuleName:"BusinessApplication"}))
        if(state?.business_application){
            updateDropdownOptions(state?.business_application)
        }
        let f1 = form?.getFieldsValue();
        if(index && f1[index]){
            if(f1[index][name]){
                setSelectedApp(f1[index][name])
            }
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(()=>{
        if(portfolioData){
            if(state[portfolioData?.moduleName]?.length>0){
                setListOptions(state[portfolioData?.moduleName]);
                setPage(1);
            }else{
                fetchFilteredData({});
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[portfolioData])

    const fetchFilteredData = async () => {
        try {
            if (portfolioData?.moduleName && !loading) {
                setLoading(true);
                let updatedFilter = {
                    moduleName: portfolioData?.moduleName || "",
                    ...filter,
                };
                updatedFilter = removeEmptyArrObjOrval(updatedFilter);
                const response = await PortfolioSettingServices?.getFilteredPortfolios( updatedFilter, page+1, 20);
                let items = [...new Map([...listOptions,...response?.data?.data].map(item =>[item["id"], item])).values()]
                
                if (response?.data?.data?.length>0) {
                    setListOptions(items);
                    setPage(Number(response?.data?.currentPage))
                    updateLocale({key:portfolioData?.moduleName,items})
                }
                updateDropdownOptions(items)
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally{
            setLoading(false);
        }
    };

    const onSearch = (value) => {
        setPage(0)
        setFilter({
            name:value
        })
        const timer = setTimeout(() => {
            fetchFilteredData()
        }, 1000);
      
        return () => {
            clearTimeout(timer);
        };
    }

    const onClear = () => {
        setPage(0)
        setFilter({})
        let f1 = form.getFieldsValue();
        form.setFieldsValue({
            ...f1,
            [index]:{
                ...f1[index],
                [name]:"",
            }
        })
    }

    const updateFormData = (value) =>{
        // console.log("console selectedApp",selectedApp);
        // console.log("console value",value);
        
        if(value===undefined || (Array.isArray( value ) && value?.length===0)){
            setSelectedApp([]);
            setFormValue([]);
        }else if(name==="sourceApp"){
            setSelectedApp([value]);
            setFormValue([value]);
        }else if(name==="targetApp"){
            if(Array.isArray( value )){
                setSelectedApp([...value]);
                setFormValue([...value]);
            }else{
                setSelectedApp([...selectedApp,value]);
                setFormValue([...selectedApp,value]);
            }            
        }
    }

    const setFormValue = (value) =>{
        let f1 = form?.getFieldsValue();
        if(index){
            form?.setFieldsValue({
                ...f1,
                [index]:{
                    ...f1[index],
                    [name]:value || "",
                }
            })
        }else{
            form?.setFieldsValue({
                ...f1,
                [name]:isNaN(value)?value:Number(value)
            })
        }
    }

    const Buttons = (item) => {
        let selected = selectedApp.includes(item?.item?.id);
        if(selected){
            return <Button disabled>Added</Button>
        }else{
            return <React.Fragment>
                <Button 
                    type="primary" 
                    icon={<PlusOutlined/>} 
                    onClick={()=>{ 
                        setListOptions(state[portfolioData?.moduleName])
                        updateDropdownOptions(state[portfolioData?.moduleName])
                        updateFormData(item?.item?.id);
                    }}
                ></Button>
            </React.Fragment>
        }
    }

    const DisableItemClass = ({item}) => {
        let f1 = form.getFieldsValue(0);

        let className = '';
        let disabled = false;

        if(name==="sourceApp"){
            if(f1[index]["targetApp"] && Array.isArray(f1[index]["targetApp"]) && f1[index]["targetApp"]?.includes(item?.id)){
                className = 'disable-item';
                disabled = true;
            }else if(f1[index]["targetApp"]===item?.id){
                className = 'disable-item';
                disabled = true;
            }
        }else if(name==="targetApp"){
            if(f1[index]["sourceApp"] && Array.isArray(f1[index]["sourceApp"]) && f1[index]["sourceApp"]?.includes(item?.id)){
                className = 'disable-item';
                disabled = true;
            }if(f1[index]["sourceApp"]===item?.id){
                className = 'disable-item';
                disabled = true;
            }
        }

        return {
            ...item,
            className,
            disabled,
        };
    }

    const updateDropdownOptions = (items)=>{
        let ops= items?.map(option=>{
            if(name==="sourceApp"){
                let temp = form.getFieldValue(index)
                if(temp?.targetApp && Array.isArray(temp?.targetApp) && temp?.targetApp?.includes(option?.id)){
                    return null;
                }else if(temp?.targetApp && temp?.targetApp===option?.id){
                    return null;
                }
            }else if(name==="targetApp"){
                let temp = form.getFieldValue(index)
                if(temp?.sourceApp && Array.isArray(temp?.sourceApp) && temp?.sourceApp?.includes(option?.id)){
                    return null;
                }else if(temp?.sourceApp && temp?.sourceApp===option?.id){
                    return null;
                }
            }
            return {
                value: Number(option?.id),
                label: <TextWithTooltip text={`${option?.displayname} (${option?.referenceid})`} characterLimit={18} />,
                option,
            }
        }).filter(f=>f!==null)
        setOptions(ops)
    }

    

    return <React.Fragment>
        <Form.Item name={[index, name]} className='mb-0'>
            <Space.Compact className="w-100">
                <Select
                    showSearch
                    allowClear
                    mode={name==="targetApp"?"multiple":false}
                    onClear={onClear}
                    value={selectedApp}
                    placeholder={title}
                    filterOption={(inputValue, currOption)=>{
                        return `${currOption?.option?.displayname || ""} ${currOption?.option?.referenceid || ""}`?.toLowerCase()?.includes(inputValue?.toLowerCase())
                    }}
                    // onPopupScroll = {fetchFilteredData}
                    onSearch={onSearch}
                    onChange={(value)=>{  
                        updateFormData(value);
                    }}
                    className='w-100'
                    onDropdownVisibleChange={(open)=>{
                        if(open){
                            updateDropdownOptions(listOptions)
                        }
                    }}
                    options={options}
                />
                {selectedApp?.length===1 && <Button icon={<InfoOutlined />} onClick={()=>{ navigateTo(portfolioData?.route+"/"+selectedApp,true) }} style={{width:35}}/>}
                <Button icon={<PlusOutlined/>} onClick={()=>{ setOpenModuleList(true) }} style={{width:35}}/>
            </Space.Compact>
        </Form.Item>
        {
            openModuleList && 
            <PortfolioListModal 
                open={openModuleList}
                setOpen={setOpenModuleList}
                moduleName={portfolioData?.routeModuleName}
                Buttons={Buttons}
                DisableItemClass={DisableItemClass}
                title={title}
            />
        }
    </React.Fragment>
}

export default AppsDropdown