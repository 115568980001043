import * as XLSX from 'xlsx';

const exportExcel = ({name=(new Date())?.toString(),records=[]}) => {
    if(!name){
        console.error("Name is not provided.");
    }
    const worksheet = XLSX.utils.json_to_sheet(records);
    Object.entries(worksheet).forEach(([key, cell]) => {
        if(cell['v']?.startsWith(`data:image/`)){
            // worksheet[key].l = { 
                // Target: cell['v'], 
                // Tooltip: 'Click here' 
            // };
            worksheet[key].v = "Diagram";
        }else if(cell['v']?.startsWith(`https://`) || cell['v']?.startsWith(`http://`)){
            worksheet[key].l = { 
                Target: cell['v'], 
                Tooltip: 'Click here' 
            };
            worksheet[key].v = "Link";
        }
        
        if(worksheet[key]?.v?.length>32767){
            worksheet[key].v = "Text length must not exceed 32767 characters";
        }
    });
    // console.log("worksheet",worksheet);
    
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Write the workbook to a file and download
    XLSX.writeFile(workbook, `${name}.xlsx`);
}

export default exportExcel;