import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Flex, Form, Image, Layout, Popover, Row, Select, Spin, Tooltip, message} from 'antd';
import { CloseOutlined, DownloadOutlined, DownOutlined, EllipsisOutlined, EyeFilled, } from "@ant-design/icons";
import FilterRightSideBar from '../../applicationlist/filter/FilterRightSideBar';
import { dataAndInfoObjIntLand, debounceTimeOut, getSelectProperties, removeEmptyArrObjOrval, removeEmptyKeys, transformText } from '../../../utils/Common';
import PortfolioSettingServices from '../../../services/services/PortfolioSettingsService';
import { usePortfolioSetting } from '../../../Context/portfolioSettingProvider';
import { getMenuPortfolio } from '../../../utils/settingCommon';
import useDebounce from '../../../helper/useDebounce';
import { useLocation } from 'react-router';
import TextWithTooltip from '../../../utils/TextWithTooltip';
import { usePortfolioTag } from '../../../Context/portfolioTagProvider';
import useFetchPortfolioTag from '../../../hooks/useFetchPortfolioTag';
import usePortfolioFetcher from '../../../hooks/usePortfolioFetcher';
import Delayed from '../../../utils/Delayed';
import LoadingBar from 'react-top-loading-bar'
import Checkbox from 'antd/es/checkbox/Checkbox';
import PlantUmlEncoder from 'plantuml-encoder';

const lifeCycleStagesColors = {
    active: "#FFB381",
    phaseIn: "#FFF280",
    phaseOut: "#FC819E",
    endOfLife: "#B2B377",
    color5: "#B5C0D0",
    color6: "#BFEF82",
    color7: "#90DDF9",
};

const lifeCycleStagesColors2 = {
    phaseIn: "#FDCB6E",
    phaseOut: "#81ECEC",
    retired: "#B9B4FF",
    inUse: "#FE8888",
};

const colorStyleCard = {
    width:15,
    height:15,
    border:'1px solid #ddd',
    borderRadius:3,
}

const selectDefaultProps = {
    showSearch:true,
    style:{minWidth:'150px'},
    size:'medium',
    allowClear:true,
}

const   BusinessApplicationGrid = ({HeaderContent,activePage:activeGridStyle,moduleName,showColorIndicator,isIntegrationLandscape,...props}) => {
    const ref = useRef(null)
    const rowsContentWidthRef = useRef(null);
    const location = useLocation();
    const [showFilter, setShowFilter] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState({});
    const [loading,setLoading] = useState(true); // Business Capability
    const [portfolioData, setPortfolioData] = useState(null);
    const [displayProperties, setDisplayProperties] = useState([]);
    const [rowsContentWidth, setRowsContentWidth] = useState(window?.innerWidth-335);
    const [swimLaneSelectedFilters, setSwimLaneSelectedFilters] = useState({});
    const [colorIndicators, setColorIndicators] = useState([]);
    const [grouppingFilter, setGrouppingFilter] = useState({
        // "grouping_1":"BusinessCapability", 
        // "grouping_2":"",
    });
    const [records, setRecords] = useState([]);

    // Submit filter state it should work only apply is clicked
    const [filterSubmit, setFilterSubmit] = useState(false);
    const [selectedRelationFilters, setSelectedRelationFilters] = useState({});

    const { state: stateTag, dispatch: dispatchTag } = usePortfolioTag();
    const { loading: tagLoading, error: tagError } = stateTag;
    const { error: errorTag, fetchTagsData } = useFetchPortfolioTag();
    const { state: portfolioSettingState, dispatch: portfolioSettingDispatch } = usePortfolioSetting();
    const { loading: PortfolioLoading, fetchPortfolio } = usePortfolioFetcher();
    const [dataAndInfo, setDataAndInfo] = useState(dataAndInfoObjIntLand);

    //Plant UML states
    const [src, setSrc] = useState("");

    useEffect(()=>{
        resetStates();
        setPortfolioData(getMenuPortfolio({routeModuleName:moduleName}))
    },[moduleName]);

    const resetStates = ()=>{
        setRecords([]);
        setGrouppingFilter({});
        setColorIndicators([]);
        setDisplayProperties([]);
        setSelectedFilters({});
        setSelectedRelationFilters({});
        setShowFilter(false);
        setFilterSubmit(false);
        setSrc("");
        setDataAndInfo([]);
    }

    useEffect(()=>{
        setLoading(PortfolioLoading);
    },[PortfolioLoading]);

    useEffect(()=>{
        setLoading(tagLoading);
    },[tagLoading]);

    useEffect(()=>{
        if (portfolioData?.moduleName) {
            dispatchTag({ type: "EMPTY_TAGS" });
            dispatchTag({
              type: "SET_MODULE_NAME",
              payload: portfolioData?.moduleName,
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[portfolioData])
    
    useEffect(()=>{
        setTimeout(() => {
            let dimensions = getDimensions(rowsContentWidthRef);
            setRowsContentWidth(dimensions?.width);
        }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[showFilter,rowsContentWidthRef?.current?.clientWidth]);
    
    const fetchFilteredData = async (filter) => {
        try {
            setLoading(true);
            setRecords([]);
            if (ref.current) {ref.current.continuousStart()}
            if(portfolioData?.moduleName && Object.values(grouppingFilter).length>0){
                let allFields = getAllFields({portfolioData:portfolioData});
                const selectedProperties = [];
                allFields.map(f=>{
                    displayProperties.includes(f.id) && selectedProperties.push(f.name)
                });
                // console.log(selectedProperties,"======allFields");
                // return;
                let  updatedFilter = {
                    moduleName: portfolioData?.moduleName || "",
                    selectedProperties: selectedProperties,
                    ...grouppingFilter,
                    ...displayProperties,
                    ...filter,
                    isIntegrationLandscape
                };
                await getSwimlaneViewData(updatedFilter)
            }
            if (ref.current && records.length ) {ref.current.complete()}
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false); 
            if (ref.current) {ref.current.complete()}        
        }
    };  

    const getSwimlaneViewData =async (filter) => {
        try {
            setLoading(!loading);
            // eslint-disable-next-line no-useless-computed-key
            props?.setReportFilter({...filter,displayProperties,["colorIndicators"]:colorIndicators?.key || ""});
            const response = await PortfolioSettingServices?.getSwimlaneViewData( filter );
            if (response?.data?.data[0]?.plantUML && isIntegrationLandscape) {
                const encode = PlantUmlEncoder.encode(response?.data?.data[0]?.plantUML);
                const plantUMLUrl = `https://plantuml.truerouta.com/svg/${encode}`;
                setSrc(plantUMLUrl);
            }else{
                const lists = response?.data?.data?.map(item=>{
                    item.moduleName = moduleName;
                    return item;
                });
                setSrc("");
                if (lists?.length) {
                    setRecords(lists);
                } else {
                    // //message.info("No records found.");
                    setRecords(response?.data?.data);
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(!loading)
        }
        
    }

    useEffect(()=>{
        if (ref.current && records.length ) {
            ref.current.complete()
        }
    },[records.length])

    // Filter and debounce
    const debouncedFilter = useDebounce(selectedFilters, debounceTimeOut);
    useEffect(() => {
      if (filterSubmit) {
        if(Object.values(grouppingFilter)?.length === 0){
            return message.info("Please select group by.");
        }

        fetchFilteredData(removeEmptyArrObjOrval(debouncedFilter));
        const timer = setTimeout(() => {
          setFilterSubmit(false);
        }, 500);
        return () => clearTimeout(timer);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterSubmit, debouncedFilter, portfolioData, grouppingFilter]);

    useEffect(() => {
        if(props?.iframeData && portfolioSettingState?.data?.length !== 0){
            let p = getMenuPortfolio({moduleName:props?.iframeData?.moduleName});
            setPortfolioData(p)
            setGrouppingFilter(props?.iframeData);
            setSelectedFilters(props?.iframeData);
            setDisplayProperties(props?.iframeData?.displayProperties);
            
            let indicators = getAllFields({portfolioData:p});
            if(indicators?.length>0 && props?.iframeData?.colorIndicators){
                let inds = indicators.find(f=>f.name===props?.iframeData?.colorIndicators);
                setColorIndicators({
                    field:inds,
                    key:inds?.name,
                    indicators:inds?.propertyOptions
                }) 
            }
            
            setTimeout(() => {
                getSwimlaneViewData(removeEmptyArrObjOrval(props?.iframeData));
            }, 100);
          }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.iframeData,portfolioSettingState])

    const getRowsContent = (items) =>{ 
        let groupingItems = generateGroupData({items,groupBy:grouppingFilter?.grouping_1});
        let grouping1Items = groupingItems?.filter(f=>f?.portfolioModuleName===grouppingFilter?.grouping_1);
        grouping1Items = [...new Map(grouping1Items.map(item => [item['portfolioItemId'], item])).values()];

        if(grouppingFilter?.grouping_2){
            return <React.Fragment key={Math.random()}>
                {
                    grouping1Items?.map(item=>{
                        return <React.Fragment key={Math.random()}><RowsContent item={item} groupingItems={groupingItems} getColumnsContent={getColumnsContent} navigateTo={navigateTo}/></React.Fragment>
                    })
                }
            </React.Fragment>
        }else{
            return <SwimlaneContent 
                records={records} 
                grouppingFilter={grouppingFilter} 
                portfolioData={portfolioData} 
                navigateTo={navigateTo} 
                colorIndicators={colorIndicators} 
                displayProperties={displayProperties} 
                allFields={getAllFields({portfolioData})}
            />
        }

        
        
    }
    
    const getColumnsContent = ({column}) =>{
        let apps = [];
        records?.forEach(record=>{
            let a = record?.portfolioRelationships?.filter(f=>f.nodeTwo===column?.portfolioItemId);
            if(a?.length>0){
                apps = [...apps,record];
            }
        })
        let groupingColumns = generateGroupData({items:apps,groupBy:grouppingFilter?.grouping_2});

        return groupingColumns?.map(col=>{
            let columnApps = [];
            apps?.forEach(record=>{
                let a = record?.portfolioRelationships?.filter(f=>f.nodeTwo===col?.portfolioItemId);
                if(a?.length>0){
                    columnApps = [...columnApps,record];
                }
            });
            return <React.Fragment key={Math.random()}>
                <ColumnContent 
                    col={col} 
                    columnApps={columnApps} 
                    navigateTo={navigateTo} 
                    displayProperties={displayProperties} 
                    portfolioData={portfolioData} 
                    allFields={getAllFields({portfolioData})}
                    colorIndicators={colorIndicators}
                />
            </React.Fragment>
        });
    }

    const navigateTo = ({routeModuleName,moduleName,id})  =>{
        let moduleData = getMenuPortfolio({routeModuleName,moduleName});
        let newLink = moduleData?.route ?moduleData?.route+"/"+id :'/';
        let url = newLink + '?from=' + encodeURIComponent(location.pathname) + '&defaultActiveKey=2';
        // Opening the URL in a new tab
        let win = window.open(newLink, '_blank');
        if (win) {
            // Browser has allowed it to be opened
            win.focus();
        }
        // navigate(newLink,{ state: {from:location?.pathname,defaultActiveKey:2}});
    }

    const fetchAllPortfolioTags = async () => {
        try {
            await fetchTagsData();
        } catch (error) {
            console.error("Error fetching portfolio:", error);
            message.error("Something went wrong.");
        }
    };

    useEffect(() => {
        if (stateTag?.moduleName) {
            fetchAllPortfolioTags();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateTag?.moduleName]);

    const fetchAllPortfolio = async () => {
        try {
          const response = await fetchPortfolio(portfolioSettingDispatch, 1, 100);
          if (response) {
            // message.success("Portfolio fetched successfully.");
          } else {
            message.error("Portfolio not found.");
          }
        } catch (error) {
          console.error("Error fetching portfolio:", error);
          message.error("Something went wrong.");
        }
    };

    useEffect(() => {
        if (portfolioSettingState?.data?.length === 0) {
            fetchAllPortfolio();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [portfolioSettingState?.data]);

    useEffect(() => {
        // eslint-disable-next-line no-useless-computed-key
        props?.setReportFilter({...props?.reportFilter,displayProperties,["colorIndicators"]:colorIndicators?.key || ""});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayProperties,colorIndicators])

    return (
        <><LoadingBar color='#1f1f1f' ref={ref} />
        <Spin className="loading_bx" size="small" spinning={loading}>
            <div className="main-wrapper">
                <Layout className='applicationlist-page'>
                    <Layout.Content className='left_pnlbx'>
                        <div className='container-fluid'>
                        {HeaderContent}
                        <div className='mr-2 mt-2' ref={rowsContentWidthRef}>
                        {props?.showFilterContent && <FilterSwimlaneData portfolioData={portfolioData} setDisplayProperties={setDisplayProperties} setColorIndicators={setColorIndicators} setGrouppingFilter={setGrouppingFilter} setFilterSubmit={setFilterSubmit} selectedFilters={selectedFilters} showFilter={showFilter} moduleName={moduleName} showColorIndicator={showColorIndicator} isIntegrationLandscape={isIntegrationLandscape}/>}
                            <div className='mt-4 w-100'></div>
                            <LandscapeContent isIntegrationLandscape={isIntegrationLandscape} loading={loading} records={records} getRowsContent={getRowsContent} rowsContentWidth={rowsContentWidth} showColorIndicator={showColorIndicator} src={src} showFullContent={props?.showFullContent} selectedGroup = {grouppingFilter?.grouping_1} portfolioData={portfolioData} heading={props?.heading}/>
                        </div>
                        </div>
                        </Layout.Content>
                        {props?.showFilterContent && <FilterRightSideBar
                            activeGridStyle={activeGridStyle}
                            lifeCycleStagesColors={lifeCycleStagesColors}
                            showFilter={showFilter}
                            setShowFilter={setShowFilter}
                            portfolioData={getSelectProperties(portfolioData?.portfolioSections,true,)}
                            selectedFilters={selectedFilters}
                            setSelectedFilters={(value) => {
                                setSelectedFilters(value);
                            }}
                            optionalLevel={false}
                            setSwimLaneSelectedFilters={setSwimLaneSelectedFilters}
                            SidebarHeight={window?.innerHeight-50}
                            filterSubmit={filterSubmit}
                            setFilterSubmit={setFilterSubmit}
                            selectedRelationFilters={selectedRelationFilters}
                            setSelectedRelationFilters={setSelectedRelationFilters}
                            showSortBy={false}
                        />}
                    </Layout>
                    
                </div>
            </Spin>
        </>
    )
}

BusinessApplicationGrid.defaultProps ={
    moduleName:'BusinessApplication',
    activePage:2,
    HeaderContent:()=>{},
    isIntegrationLandscape:false,
    showColorIndicator:false,
    showFilterContent:true,
    iframeData:null,
    setReportFilter :()=>{},
    reportFilter:{},
}

export default BusinessApplicationGrid

const RowsContent = ({item,groupingItems,navigateTo,getColumnsContent}) =>{
    const rowsContentHeightRef = useRef(null);
    const rowsContentHeight2Ref = useRef(null);
    const [rowStyle,setRowStyle] = useState({});

    useEffect(()=>{
        setTimeout(() => {
            let dimensions = getDimensions(rowsContentHeightRef);
            let dimensions2 = getDimensions(rowsContentHeight2Ref);

            if(dimensions?.height>dimensions2?.height){
                setRowStyle({
                    minHeight:dimensions?.height || 0
                })
            }else{
                setRowStyle({
                    minHeight:dimensions2?.height || 0
                })
            }
        }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const getLink = ({routeModuleName,moduleName,id})  =>{
        let moduleData = getMenuPortfolio({routeModuleName,moduleName});
        return window.location.origin+moduleData?.route ?moduleData?.route+"/"+id :'/';
    }

    return <React.Fragment key={Math.random()}>
        <tr>
            <td className='td-heading justify-content-start' style={rowStyle} ref={rowsContentHeight2Ref}>
                <a href={getLink({routeModuleName:item?.portfolioModuleName,id:item?.portfolioItemId})} className='cursor-pointer fw-500' target='_blank' rel="noreferrer" onClick={()=>{ navigateTo({routeModuleName:item?.portfolioModuleName,id:item?.portfolioItemId}) }}>
                    {item?.currentData?.nodeTwoDisplayname}
                </a>
            </td>
            <td ref={rowsContentHeightRef} valign="top" align='left'>
                <div className='d-flex swimlane_listbx mx-2'>
                    {getColumnsContent({column:item})}
                </div>
            </td>
        </tr>
    </React.Fragment>
}

const generateGroupData = ({items,groupBy}) => {
    let groupingItems = [];
    items?.forEach((item)=>{
        if(item?.portfolioRelationships?.length>0){
            item?.portfolioRelationships?.forEach((node)=>{
                let tempData = {};
                tempData.portfolioItemId = node?.nodeTwo;
                tempData.portfolioModuleName = node?.nodeTwoTag;
                tempData.parentData = item;
                tempData.currentData = node;
                tempData.displayName = node?.nodeTwoDisplayname;
                
                if(groupBy===node?.nodeTwoTag){
                    groupingItems.push(tempData);
                }
            })
        }
    });

    groupingItems = groupingItems?.filter(f=>f?.portfolioModuleName===groupBy);
    groupingItems = [...new Map(groupingItems.map(item => [item['portfolioItemId'], item])).values()];

    return groupingItems;
}

const AppsContent = ({appData,navigateTo,...props}) =>{
    const getBoxColor = ()=>{
        let boxFieldName = props?.colorIndicators?.key;
        let boxValue = appData[boxFieldName];
        if(boxFieldName){
            let hexColor =  props?.colorIndicators?.indicators?.find(f=>f.id===Number(boxValue))?.color || '#FFFFFF';
            return contrastColorStyle(hexColor);
        }else{
            return {
                backgroundColor:'#FDCB6E',
                color:'#000',
            };
        }
    }

    const getLink = ()  =>{
        let m = appData?.moduleName;
        let moduleData = getMenuPortfolio({routeModuleName:m,moduleName:m});
        moduleData = moduleData ? moduleData : getMenuPortfolio({moduleName:m});
        return window.location.origin+moduleData?.route ?moduleData?.route+"/"+appData?.id :'/';
    }

    return <React.Fragment key={Math.random()}>
        <a href={getLink({routeModuleName:appData?.moduleName,id:appData?.id})} target='_blank' rel="noreferrer" className='card card-body px-2 py-1 line-height-normal cursor-pointer' style={getBoxColor()} >
            <React.Fragment>
                <div className='fw-500 coursor-pointer cu-fs-smaller' style={getBoxColor()}>
                    <TextWithTooltip 
                        text={appData?.displayname} 
                        tooltipText={`Display Name: ${appData?.displayname}`}
                        characterLimit={'auto'}
                        isTooltip
                    />
                </div>
                <div className="coursor-pointer cu-fs-smaller" style={getBoxColor()}>
                    <TextWithTooltip 
                        text={appData?.referenceid ? trimRefrence(appData?.referenceid) : ''} 
                        tooltipText={`Reference Id: ${appData?.referenceid ? appData?.referenceid : null}`}
                        characterLimit={'auto'}
                        isTooltip
                    />
                </div>
            </React.Fragment>
            {
                ((()=>{
                    if(props?.displayProperties?.length>0){

                        return props?.displayProperties?.map(itemId=>{
                            let fieldData = props?.allFields.find(f=>f.id===itemId);
                            if(fieldData.name!=="displayname" && fieldData.name!=="referenceid"){
                                return <React.Fragment key={Math.random()}>
                                    <div className='cu-fs-smaller cu-word-break-all' style={getBoxColor()}>
                                        <TextWithTooltip 
                                            text={appData[fieldData?.name]?getFieldValue({fieldData,value:appData[fieldData?.name]}):'-'} 
                                            tooltipText={`${fieldData?.displayName}: ${appData[fieldData?.name]?getFieldValue({fieldData,value:appData[fieldData?.name]}):'-'}`} 
                                            characterLimit={'auto'}
                                            isTooltip
                                        />
                                    </div>
                                </React.Fragment>
                            }
                        });
                    }
                })())
            }
        </a>
    </React.Fragment>
}

const ColumnContent = ({columnApps,col, ...props}) =>{
    return <React.Fragment key={Math.random()}>
    <div className='card mb-1 card-group-2' style={{width:300,height:'fit-content'}}>
        <div className='card-body px-1 py-1'>
            <div className='text-center m-0'>
                <span className='fw-500 cursor-pointer' onClick={()=>{ props?.navigateTo({routeModuleName:col?.portfolioModuleName,id:col?.portfolioItemId}) }}><TextWithTooltip text={col?.currentData?.nodeTwoDisplayname} characterLimit={30}/></span>
            </div>
            <Row gutter={[4,4]} className='justify-content-left m-1'>
                {
                    columnApps?.map(item=>{
                        return <React.Fragment key={Math.random()}><Col span={12}><AppsContent appData={item} {...props}/></Col></React.Fragment>
                    })
                }
            </Row>
        </div>
    </div>
</React.Fragment>
}

const getDimensions = (ref) => {
    const { current } = ref
    return { width: Math.round(current?.clientWidth), height: Math.round(current?.clientHeight) };
}

export const getAllFields = ({portfolioData}) => {
    let fields = [];
    portfolioData?.portfolioSections?.forEach(section => {
        section?.properties?.forEach(field=>{
            field.section = section;
            fields.push(field);
        })
    });
    return fields;
}

const getFieldValue = ({fieldData,value}) =>{
    let {type,propertyOptions} = fieldData;
    if(type==="Free text"){
        return value || "-";
    }else if(type==="Single select"){
        return propertyOptions?.find(f=>f.id===value)?.name || "-";
    }else if(type==="Numeric"){
        return value || "-";
    }else if(type==="Multi select"){
        return propertyOptions?.filter(f=>value?.includes(f?.id))?.map(item=>{ return item?.name }).join(",");
    }
}

const trimRefrence = (string, char="") => {
    if (char == null) char = ' '; // Handle null or undefined case by setting it to space
    if(!char) char = ' '; // Space by default if char is an empty string
    char = char.replace(/([()[{*+.$^\\|?])/g, '\\$1'); // Escape char parameter if needed for regex syntax
    var regex_1 = new RegExp("^" + char + "+", "g");
    var regex_2 = new RegExp(char + "+$", "g");
    return '(' + string.replace(regex_1, '').replace(regex_2, '') + ')';
}

const contrastColorStyle = (hexColor) => {
    var r = parseInt(hexColor.substring(1,3),16);
    var g = parseInt(hexColor.substring(3,5),16);
    var b = parseInt(hexColor.substring(5,7),16);
    var yiq = ((r*299)+(g*587)+(b*114))/1000;
    var color = (yiq >= 125) ? '#000' : '#fff';
    return {
        backgroundColor:hexColor,
        color,
    };
}

const getAllNodes = (apps) =>{
    let groupingItems = [];

    apps?.forEach((item)=>{
        if(item?.portfolioRelationships?.length>0){
            item?.portfolioRelationships?.forEach((node)=>{
                node.app = item;
                groupingItems.push(node);
            })
        }
    });
    groupingItems = [...new Map(groupingItems.map(item => [item['id'], item])).values()];
    return groupingItems;
}

const generateSwimLaneData = ({records,grouppingFilter,portfolioData}) => {
    let allNodes = getAllNodes(records);

    const getApps = (item)=>{
        let apps = records?.map(({portfolioRelationships,...extraData})=>{
            if(portfolioRelationships.find(f=>((f.nodeOneTag===item?.routeModuleName && f.nodeOne===item?.id) || (f.nodeTwoTag===item?.routeModuleName && f.nodeTwo===item?.id)))){
                return {
                    ...extraData,
                    routeModuleName: portfolioData?.routeModuleName,
                    portfolioRelationships,
                }
            }
            return null;
        }).filter(f=>f!==null)

        return apps;
    }

    let rows = allNodes?.filter(f=>(f.nodeTwoTag===grouppingFilter?.grouping_1 || f.nodeOneTag===grouppingFilter?.grouping_1) && (f.nodeOneTag===portfolioData?.routeModuleName || f.nodeTwoTag===portfolioData?.routeModuleName))?.map(row=>{
        if(row?.nodeOneTag===grouppingFilter?.grouping_1){
            return {
                label :  row?.displayName,
                id : row?.nodeOne,
                routeModuleName: row?.nodeOneTag,
                row,
            };
        }else if(row?.nodeTwoTag===grouppingFilter?.grouping_1){
            return {
                label :  row?.displayName,
                id : row?.nodeTwo,
                routeModuleName: row?.nodeTwoTag,
                row,
            };
        }
        return null;
    }).filter(f=>f!==null)?.map(row=>{
        let childrens = getApps(row)
        row.childrens = [...new Map(childrens.map(item => [item['id'], item])).values()];
        return row;
    });

    rows = [...new Map(rows.map(item => [item['id'], item])).values()];



    return rows;
}

const SwimlaneContent = ({records,grouppingFilter,portfolioData,navigateTo,...props})=>{
    const [rows,setRows] = useState([]);
    
    useEffect(() => {
        if(records?.length){
            let items = generateSwimLaneData({records,grouppingFilter,portfolioData});
            setRows(items);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [records,grouppingFilter])

    const rowsContentHeightRef = useRef(null);
    const rowsContentHeight2Ref = useRef(null);
    const [rowStyle,setRowStyle] = useState({});

    useEffect(()=>{
        setTimeout(() => {
            let dimensions = getDimensions(rowsContentHeightRef);
            let dimensions2 = getDimensions(rowsContentHeight2Ref);

            if(dimensions?.height>dimensions2?.height){
                setRowStyle({
                    minHeight:dimensions?.height || 0
                })
            }else{
                setRowStyle({
                    minHeight:dimensions2?.height || 0
                })
            }
        }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    if(records?.length>0 && rows?.length===0){ return <React.Fragment key={Math.random()}>
            <tr>
                <td>
                    <Delayed waitBeforeShow={1000}><div className={`text-base font-bold  `} > No items found. </div></Delayed>
                </td>
            </tr>
        </React.Fragment> 
    }

    const getLink = ({routeModuleName,moduleName,id})  =>{
        let moduleData = getMenuPortfolio({routeModuleName,moduleName});
        return window.location.origin+moduleData?.route ?moduleData?.route+"/"+id :'/';
    }
    
    return rows?.map(item=>{
        return <React.Fragment key={Math.random()}>
            <tr>
                <td className='group-1-column' style={rowStyle} ref={rowsContentHeight2Ref} width={'10%'} align='center'>
                    <a href={getLink({routeModuleName:item?.routeModuleName,id:item?.id})} target='_blank' rel="noreferrer" className='cursor-pointer fw-800' onClick={()=>{ navigateTo({routeModuleName:item?.routeModuleName,id:item?.id}) }}>
                        {item?.label}
                    </a>
                </td>
                <td ref={rowsContentHeightRef} valign="top" align='left'>
                    <div className='swimlane_listbx mx-2'>
                        {
                            item?.childrens?.map(item=>{                                
                                return <React.Fragment key={Math.random()}>
                                    <div className='application-card'>
                                        <AppsContent appData={item} navigateTo={navigateTo} {...props}/>
                                    </div>
                                </React.Fragment>
                            })
                        }
                    </div>
                </td>
            </tr>
        </React.Fragment>
    })
}
const FilterSwimlaneData = ({...props}) => {
    let portfolios = sessionStorage.getItem("menu-portfolios");
	portfolios = JSON.parse(portfolios);
    const [displayProperty,setDisplayProperty] = useState([]);
    const [indicators,setIndicators] = useState([]);
    const [colorIndicatorValue,setColorIndicatorValue] = useState(null);
    const [indicator,setIndicator] = useState(null);
    const [showIndicator,setShowIndicator] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
        setDisplayProperty([]);
        setIndicators([]);
        setIndicator(null);
        props?.setColorIndicators({})
        setColorIndicatorValue(null)
    }, [props.moduleName])
    

    useEffect(()=>{
        let inds = getAllFields({portfolioData:props?.portfolioData});
        inds = inds.filter(f=>f.type==="Single select");
        setIndicators(inds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props?.portfolioData])

    const getAllOptions = () => {
        if (props.moduleName === "Technology") {
            return portfolios
              ?.filter(
                (f) =>
                  f.routeModuleName !== props?.portfolioData?.routeModuleName
              )?.filter((fa)=> fa.moduleName === "business_application")
              .map((opt) => {
                return {
                  label: opt?.name,
                  value: opt?.routeModuleName,
                  item: opt,
                };
              });
        } else {
          const findConnectedPortfolios = portfolios?.find(
            (item) =>
              item.routeModuleName === props?.portfolioData?.routeModuleName
          );

          if (
            findConnectedPortfolios &&
            findConnectedPortfolios?.portfolioConnectedIds?.length > 0
          ) {
            const filteredData = portfolios.filter(({ id }) =>
              findConnectedPortfolios?.portfolioConnectedIds.includes(id)
            );
            return filteredData
              ?.filter(
                (f) =>
                  f.routeModuleName !== props?.portfolioData?.routeModuleName
              )
              .map((opt) => {
                return {
                  label: opt?.name,
                  value: opt?.routeModuleName,
                  item: opt,
                };
              });
          } else {
            return portfolios
              ?.filter(
                (f) =>
                  f.routeModuleName !== props?.portfolioData?.routeModuleName
              )
              .map((opt) => {
                return {
                  label: opt?.name,
                  value: opt?.routeModuleName,
                  item: opt,
                };
              });
          }
        }
    };

    return <React.Fragment>
        <div className=' bg-aaaaaa6b p-1'>
            <Flex gap={8} align='center'>
                <div>
                    <Form
                        form={form}
                        onFinish={(values)=>{ 
                            props?.setGrouppingFilter(values)
                            if(Object.values(values)?.length>0){
                                props?.setFilterSubmit(true)    
                            }
                        }}
                    >
                        <Flex gap={8} align='center'>
                            <div className='fw-500 text-nowrap'>Group By <span className='text-danger'>*</span></div>
                            <Form.Item className='mb-0' name={`grouping_1`} rules={[{required:true,"message":""}]}>
                                <Select
                                    placeholder={'Select'}
                                    {...selectDefaultProps}
                                    allowClear={false}
                                    options={
                                        getAllOptions()
                                    }
                                    showSearch
                                    optionFilterProp="label"
                                />
                            </Form.Item>
                            <Button type="primary" htmlType='submit'>Go</Button>
                        </Flex>
                    </Form>
                </div>
                {<Flex gap={8} justify='' align='center'>
                    <div className='fw-500 text-nowrap'>Display Property</div>
                    <Popover
                        content={<CheckboxRenderProperties portfolioData={props?.portfolioData} setDisplayProperties={(value=>{ setDisplayProperty(value) })} showColorIndicator={props.showColorIndicator} moduleName={props.moduleName}/>}
                        trigger="click"
                        placement="bottomLeft"
                        onOpenChange={(visible)=>{ 
                            if(!visible){
                                props.setDisplayProperties(displayProperty);
                            }
                        }}

                    >
                        <Button style={{width:100}}  className='bg-none'>
                            <Flex justify='space-between' align='center'>
                                <div>{displayProperty?.length>0 ? displayProperty?.length+" Selected":"Select"}</div>
                                <div><DownOutlined /></div>
                            </Flex>
                        </Button>
                    </Popover>
                </Flex>}
                {!props.isIntegrationLandscape && props.showColorIndicator && indicators?.length>0 && (<Flex gap={8} align='center'>
                    <div className='fw-500 text-nowrap'>Color Indicator</div>
                    <Select
                        {...selectDefaultProps}
                        // defaultValue={''}
                        value={colorIndicatorValue}
                        placeholder={`Select`}
                        showSearch
                        optionFilterProp="label"
                        options={
                            indicators?.sort((a, b) => {
                                return a?.displayName.localeCompare(b?.displayName, undefined, {
                                  numeric: true,
                                  sensitivity: "base",
                                });
                            }).map(indicator=>{
                                return {
                                    label: indicator?.displayName,
                                    value : indicator?.id,
                                    item : indicator?.properties,
                                } 
                            })
                        }
                        onChange={(value)=>{ 
                            let inds = indicators.find(f=>f.id===value);
                            props?.setColorIndicators({
                                field:inds,
                                key:inds?.name,
                                indicators:inds?.propertyOptions
                            }) 
                            setColorIndicatorValue(value);
                            setIndicator(inds?.propertyOptions);
                        }}
                        
                    />
                </Flex>)}
                {!props.isIntegrationLandscape && !showIndicator && <Flex gap={8} style={{overflow:"hidden",maxWidth:350}}>
                    {
                        ((()=>{
                            if(indicator?.length>0){
                                return indicator?.sort((a, b) => {
                                    return a?.name.localeCompare(b?.name, undefined, {
                                        numeric: true,
                                        sensitivity: "base",
                                    });
                                })?.map(ind=>{
                                    return <React.Fragment key={Math.random()}>
                                        <div>
                                            <Flex 
                                                gap={4} 
                                                key={Math.random()} 
                                                align='center'
                                            >
                                                <div style={{background:ind?.color,...colorStyleCard}}/>
                                                <div className='text-nowrap fs-smaller'><TextWithTooltip text={ind?.name} characterLimit="10" isTooltip/></div>
                                            </Flex>
                                        </div>
                                    </React.Fragment>
                                })
                            }
                        })())
                    }
                </Flex>}
                {!props.isIntegrationLandscape &&  !showIndicator && indicator?.length>(props?.showFilter?1:4) && <Button type="text" icon={<EllipsisOutlined />} style={{width:"-webkit-fill-available",maxWidth: 33,}} onClick={()=>{ setShowIndicator(!showIndicator) }}/> }
            </Flex>
            {!props.isIntegrationLandscape && showIndicator && <Card
                size={`small`}
            >
                <Flex>
                    <Flex gap={8} align='center' className='ant-flex-wrap-wrap w-100' wrap >
                    {
                        ((()=>{
                            if(indicator?.length>0){
                                return indicator?.sort((a, b) => {
                                    return a?.name.localeCompare(b?.name, undefined, {
                                        numeric: true,
                                        sensitivity: "base",
                                    });
                                })?.map(ind=>{
                                    return <React.Fragment key={Math.random()}>
                                        <div style={{width:(props?.showFilter?165:150)}}>
                                            <Flex 
                                                gap={4} 
                                                key={Math.random()} 
                                                align='center'
                                            >
                                                <div style={{background:ind?.color,...colorStyleCard}}/>
                                                <div className='text-nowrap fs-smaller'><TextWithTooltip text={ind?.name} characterLimit="20" isTooltip/></div>
                                            </Flex>
                                        </div>
                                    </React.Fragment>
                                })
                            }
                        })())
                    }
                    </Flex>
                    <Button type="text" icon={<CloseOutlined />} style={{width:"-webkit-fill-available",maxWidth: 33,}} onClick={()=>{ setShowIndicator(!showIndicator) }}/>
                </Flex>
            </Card>}
            
        </div>
    </React.Fragment>
}

const CheckboxRenderProperties = ({portfolioData,showColorIndicator,...props})=>{
    const [checkedList, setCheckedList] = useState([]);
    const onChange = (list) => {
        setCheckedList(list);
    };

    useEffect(() => {
      props.setDisplayProperties([]);
      setCheckedList([]);
    }, [props?.moduleName]);
    

    useEffect(()=>{
        props.setDisplayProperties(checkedList)
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[checkedList])

    return (
      <React.Fragment>
        <Flex gap={8} justify="flex-end" align="center" className="mb-1">
          <Button
            type="text"
            onClick={() => {
              setCheckedList([]);
            }}
            size="small"
          >
            Clear All
          </Button>
        </Flex>
        <Checkbox.Group onChange={onChange} value={checkedList}>
          <Flex
            gap={4}
            vertical
            className="overflow-y"
            style={{ maxHeight: 200 }}
          >
            {portfolioData?.portfolioSections
              ?.sort((a, b) => {
                return a?.name.localeCompare(b?.name, undefined, {
                  numeric: true,
                  sensitivity: "base",
                });
              })
              ?.map((section, index) => {
                return (
                  <React.Fragment key={Math.random()}>
                    <div className="fw-500">{section?.name}</div>
                    <Flex gap={4} vertical>
                      {section?.properties
                        .filter(
                          (f) =>
                            f.name !== "displayname" && f.name !== "referenceid"
                        )
                        ?.sort((a, b) => {
                          return a?.displayName.localeCompare(
                            b?.displayName,
                            undefined,
                            {
                              numeric: true,
                              sensitivity: "base",
                            }
                          );
                        })
                        ?.map((option) => {
                          if (
                            option?.type === "Single select" &&
                            showColorIndicator
                          ) {
                            return null;
                          }
                          return (
                            <React.Fragment key={Math.random()}>
                              <Checkbox value={option?.id}>
                                {option?.displayName}
                              </Checkbox>
                            </React.Fragment>
                          );
                        })}
                    </Flex>
                  </React.Fragment>
                );
              })}
            {!showColorIndicator && (
              <React.Fragment key={Math.random()}>
                <div className="fw-500">Relations</div>
                <Flex gap={4} vertical>
                  {[
                    { id: "dataobject", displayName: "Data Object" },
                    {
                      id: "informationobject",
                      displayName: "Information Object",
                    },
                  ]
                    .filter(
                      (f) =>
                        f.name !== "displayname" && f.name !== "referenceid"
                    )
                    ?.sort((a, b) => {
                      return a?.displayName.localeCompare(
                        b?.displayName,
                        undefined,
                        {
                          numeric: true,
                          sensitivity: "base",
                        }
                      );
                    })
                    ?.map((option) => {
                      return (
                        <React.Fragment key={Math.random()}>
                          <Checkbox value={option?.id}>
                            {option?.displayName}
                          </Checkbox>
                        </React.Fragment>
                      );
                    })}
                </Flex>
              </React.Fragment>
            )}
          </Flex>
        </Checkbox.Group>
      </React.Fragment>
    );
}

export const LandscapeContent = ({
  isIntegrationLandscape,
  loading,
  records,
  getRowsContent,
  rowsContentWidth,
  showColorIndicator,
  src,
  selectedGroup,
  portfolioData,
  ...props
}) => {
    let storedPortfolios = sessionStorage.getItem("menu-portfolios");
	storedPortfolios = JSON.parse(storedPortfolios);
  return (
    <>
      {showColorIndicator && !isIntegrationLandscape ? (
        <div
          className="pr-2 integration-landscape report-wrapper"
          style={
            !props?.showFullContent
              ? {
                  overflow: "auto",
                  maxHeight: window?.innerHeight - 200,
                  width: rowsContentWidth,
                }
              : {}
          }
        >
            {selectedGroup && records?.length > 0 && <div className="title-headtag">{props.heading} - Group By <span>{storedPortfolios?.find((item)=> item?.routeModuleName === selectedGroup)?.name || selectedGroup}</span></div>}
          <div className="pr-2">
            <table className="acg-table" width={`100%`}>
              <thead>
                {records?.length > 0 && (
                //   <tr className="!bg-[#f2f2f2] !text-black !gap-2">
                //     <th className="!bg-[#f2f2f2] !text-black" width={"10%"} align="center">
                //       {storedPortfolios?.find((item)=> item?.routeModuleName === selectedGroup)?.name || selectedGroup}
                //     </th>
                //     <th className="!bg-[#f2f2f2] !text-black" align="left">{portfolioData?.name}</th>
                //   </tr>
                <tr>
                    <th className="" width={"10%"} align="center">
                      {storedPortfolios?.find((item)=> item?.routeModuleName === selectedGroup)?.name || selectedGroup}
                    </th>
                    <th className="" align="center">{portfolioData?.name}</th>
                  </tr>
                )}
              </thead>
              <tbody>
                {(() => {
                  if (!loading && records?.length === 0) {
                    return (
                      <Delayed waitBeforeShow={2500}>
                        <tr>
                          <td style={{border:"none",}}>
                            <div
                              className={`text-base font-bold ${
                                loading ? "d-none" : ""
                              }`}
                            >
                              {" "}
                              No items found.{" "}
                            </div>
                          </td>
                        </tr>
                      </Delayed>
                    );
                  } else {
                    return getRowsContent(records);
                  }
                })()}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        (src && (
        //   <Card className={!props?.showFullContent ? "table-responsive dataTable dt-avoid-y-scroll mt-2 text-start":""}>
        <Card className={!props?.showFullContent ? "table-responsive dataTable graph_container mt-2 text-start":""}>
            <div className="planuml-div integration-landscape graph_viewlist">
            {selectedGroup && <div className="title-headtag">{props.heading} - Group By <span>{storedPortfolios?.find((item)=> item?.routeModuleName === selectedGroup)?.name || selectedGroup}</span></div>}
              {/* <img alt={"planuml daigram"} src={src} /> */}
              <object data={src}></object>
            </div>
          </Card>
        )) || (
          <div
            className={`text-base font-bold ${loading ? "d-none" : ""}`}
          >
            No items found.{" "}
          </div>
        )
      )}
    </>
  );
};