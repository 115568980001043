import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Spin,
  Button,
  // Row,
  // Col,
  Modal,
  Collapse,
  Tooltip,
  message,
  Pagination,
  // Flex,
  Checkbox,
} from "antd";
import { ExclamationCircleFilled, PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  debounceTimeOut,
  deepFindByName,
  default_pageSize,
  default_pageSizeOptions,
  getSelectProperties,
  PageTitleHeading,
  removeEmptyArrObjOrval,
  removeEmptyKeys,
  transformText,
} from "../../../../utils/Common";
import {
  getNodesByModuleName,
  // getPortfolioID,
} from "../../../../utils/portfolioRelationshipCommon";
import PortfolioRelationshipServices from "../../../../services/services/PortfolioRelationshipService";
import { usePortfolioSetting } from "../../../../Context/portfolioSettingProvider";
import { usePortfolioTag } from "../../../../Context/portfolioTagProvider";
import usePortfolioFetcher from "../../../../hooks/usePortfolioFetcher";
import { usePortfolioRelationship } from "../../../../Context/portfolioRelationshipProvider";
import useFetchPortfolioTag from "../../../../hooks/useFetchPortfolioTag";
import usePortfolioRelationshipModify from "../../../../hooks/usePortfolioRelationshipModify";
import PortfolioSettingServices from "../../../../services/services/PortfolioSettingsService";
import FilterRightSideBar from "../../../applicationlist/filter/FilterRightSideBar";
import {
  getMenuPortfolio,
  // staticSettingPortfolioList,
} from "../../../../utils/settingCommon";
import { useParams } from "react-router";
// import { applicationsList } from "../../../../utils/businessApplication";
// import usePortfolioRelationshipFetch from "../../../../hooks/usePortfolioRelationshipFetch";
import { useMenu } from "../../../../Context/MenuProvider";
import { useApplicationlink } from "../../../../Context/linkProvider";
import useDebounce from "../../../../helper/useDebounce";
import BusinessApplicationListCards from "../../../applicationlist/BusinessApplicationListCards";
import { checkRole } from "../../../../helper/useUserData";
import ScenarionServices from "../../../../services/services/ScenarioServices";

const conBc = [
  {
    id: 1,
    title: "hello",
    description: "test",
  },
  {
    id: 2,
    title: "hello me",
    description: "test",
  },
  {
    id: 3,
    title: "hello me to",
    description: "test",
  },
];

const { Panel } = Collapse;
const modalContentHeight = window?.innerHeight - 350;

const PortFolioItems = ({
  item: portfolioSettingData,
  data,
  loading,
  setLoading,
  refreshTab,
  setrefreshTab
}) => {
  const [activePanel, setactivePanel] = useState([]);
  const [moduleName, setmoduleName] = useState();
  const [moduleNameTemp, setmoduleNameTemp] = useState("");
  const [modelNameTemp, setmodelNameTemp] = useState("");
  const [showApplicationListModal, setShowApplicationListModal] = useState(false);
  const [page, setPage] = useState(1);
  const [addedData, setAddedData] = useState([]);
  const [selectedCollapse, setSelectedCollapse] = useState(-1);
  const { state: linkState, dispatch: linkDispatch } = useApplicationlink();
  const [filters, setFilters] = useState({
    nodeId: linkState?.businessApplicationId || null,
    nodeTag: linkState?.businessApplicationModel || "",
  });

  // const [portfolioSettingData, setPortfolioSettingData] = useState([]);
  // const [portfolioList, setPortfolioList] = useState([]);
  const { state: stateTag, dispatch: dispatchTag } = usePortfolioTag();
  const { fetchPortfolioNodesById } = usePortfolioFetcher();
  const { state, dispatch } = usePortfolioRelationship();
  let { id = null,id : modelIDScenario, moduleName: moduleNamePath, scenarioId } = useParams();
  const { error: errorTag, fetchTagsData } = useFetchPortfolioTag();

  const { state: portfolioSettingState, dispatch: portfolioSettingDispatch } = usePortfolioSetting();

  // const { state: portfolioConnection, dispatch: portfolioConnectionDispatch } =
  // usePortfolioSetting();

  const [filterApplication, setFilterApplication] = useState([]);
  const [searchTextApplication, setSearchTextApplication] = useState("");
  const [filterSubmit, setFilterSubmit] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [showListModal, setShowListModal] = useState(false);
  const [filterLevel, setFilterLevel] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [filterBy, setFilterBy] = useState("");
  const {
    addPortfolioRelationship,
    removePortfolioRelationship,
    dataLoading,
    error    
  } = usePortfolioRelationshipModify();
  const { stateMenu, dispatchMenu } = useMenu();
  const [freeText, setfreeText] = useState("Data Not Found");
  const [showFilter, setShowFilter] = useState(false);

  const [listApplication, setListApplication] = useState([]);
  const [limit, setLimit] = useState(default_pageSize);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [selectedRelationFilters, setSelectedRelationFilters] = useState({});
  const [lists, setLists] = useState([]);
  const [removeRelationshipOpen, setRemoveRelationshipOpen] = useState(false);
  const [checkedList, setCheckedList] = useState([]);

  useEffect(()=>{
    console.log("testing checkbox values>>>>>>>>>>>>>>>>",checkedList);
    
  },[checkedList])

  useEffect(() => {
    if (moduleNameTemp) {
      getBusinessPortfolioList_Modulewise(moduleNameTemp, modelNameTemp);
    }
  }, [page, limit]);

  // const loadingState = usePortfolioRelationshipFetch(filters);

  const fetchDataRelations = useCallback(async () => {
    try {
      let relationshipsUpdate = await PortfolioRelationshipServices.getAllNode({
        nodeId: id,
        nodeTag: moduleNamePath,
      });
      dispatch({
        type: "SET_PORTFOLIO_RELATIONSHIPS",
        payload: relationshipsUpdate?.data?.data,
      });
    } catch (error) {
      console.error("Error fetching portfolio relationships:", error);
      message.error("Failed to fetch portfolio relationships");
    }
  }, [id, moduleNamePath, dispatch]);

  const filterArrayBySearchText = (array, searchText) => {
    const filterdArr = array.filter(
      (item) =>
        item?.name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
        item?.DisplayName?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
        item?.NameInSourceSystem?.toLowerCase()?.includes(
          searchText?.toLowerCase()
        )
    );
    return filterdArr;
  };

  const getBusinessPortfolioList_Modulewise = async ({
    moduleName,
    modelName,
  }) => {
    if (!moduleName) {
      moduleName = moduleNameTemp;
    }
    setfreeText("Fetching Data....");
    try {
      const list = await PortfolioRelationshipServices.getModuleData({
        moduleName: moduleName,
        page: page,
        limit: limit,
      });

      const recordLists = list?.data?.data || [];
      const transformedData =
        recordLists.map((item) => ({
          ...item,
          moduleName:
            transformText(moduleName.replace("_", " "), "pascal") || modelName,
        })) || [];

      setListApplication(transformedData);
      setFilterApplication(transformedData);
      setTotalRecords(list?.data?.totalCount || 0);

      if (recordLists.length < 1) {
        setfreeText("No items found.");
      }
    } catch (err) {
      console.error("Error fetching module data:", err);
      message.error("Failed to fetch module data");
    }
  };

  const addedPortfolioList = (modelName) => {
    const portfolio_tmp = transformText(modelName.replace("_", " "), "pascal");
    const addedPortfolio_arr = [];
    data &&
      data?.relations?.length &&
      Object.keys(
        getNodesByModuleName(
          data?.relations,
          portfolio_tmp
          // applicationsList.businessApplication
        )
      ).length > 0 &&
      getNodesByModuleName(
        data?.relations,
        portfolio_tmp
        //applicationsList.businessApplication
      )?.tags?.length &&
      getNodesByModuleName(
        data?.relations,
        portfolio_tmp
        //applicationsList.businessApplication
      )?.tags?.map((item, index) => {
        addedPortfolio_arr.push(item.id);
      });
    setAddedData(addedPortfolio_arr);
  };

  const handleAddRelationship = async ({ id, moduleName }) => {
    try {
      const addedData = await addPortfolioRelationship(
        { id, moduleName },
        fetchDataAfterOperation
      );
 
      if (scenarioId) {
        const objdata = {
          source_id: modelIDScenario, 
          modulename: portfolioSettingData?.moduleName,
          tab_name: "Relation",
        };
  
        const obj= await ScenarionServices.checkscenariomodifyrecord(objdata);
          
      }

      setListApplication(
        listApplication?.map((item) => {
          return { ...item, relationShipId: addedData[0]?.id || null };
        }) || []
      );
      setFilterApplication(
        filterApplication?.map((item) => {
          return { ...item, relationShipId: addedData[0]?.id || null };
        }) || []
      );
    } catch (error) {
      console.error("Error adding relationship:", error);
      console.error("Error adding relationship:", error);
    }
    setrefreshTab(!refreshTab)
  };

  const handleRemoveRelationship = async ({ id, moduleName, uniqueid }) => {
    try {
      let checkedData = sessionStorage.getItem("handleRemoveRelationship");
      if(checkedData){
        return handleRemoveRelationship2({ id, moduleName, uniqueid });
      }
      Modal.confirm({
        centered: true,
        title: "Do you want to unlink this item?",
        className:"modal-justify-start",
        icon: <ExclamationCircleFilled />,
        // content: 'Some descriptions',
        content: <React.Fragment>
          <Checkbox 
            onClick={(e)=>{
              sessionStorage.setItem("handleRemoveRelationship",e?.target?.checked);
            }}
          >Don't Show again</Checkbox>
        </React.Fragment>,
        onOk() {
          handleRemoveRelationship2({ id, moduleName, uniqueid });
          // removePortfolioRelationship(id, fetchDataAfterOperation, uniqueid);
          
          // if (scenarioId) {
          //   const objdata = {
          //     source_id: modelIDScenario, 
          //     modulename: portfolioSettingData?.moduleName,
          //     tab_name: "Relation",
          //   };
      
          //   const obj=  ScenarionServices.checkscenariomodifyrecord(objdata);
              
          // }
        },
        onCancel() {
          // console.log('Cancel');
          sessionStorage.removeItem("handleRemoveRelationship");
        },
      });
    } catch (error) {
      console.error("Error removing relationship:", error);
    }
  };

  const handleRemoveRelationship2 = async({ id, moduleName, uniqueid }) =>{
    await removePortfolioRelationship(id, fetchDataAfterOperation, uniqueid);
          
    if (scenarioId) {
      const objdata = {
        source_id: modelIDScenario, 
        modulename: portfolioSettingData?.moduleName,
        tab_name: "Relation",
      };

      const obj=  await ScenarionServices.checkscenariomodifyrecord(objdata);
        
    }
  }

  const fetchDataAfterOperation = async () => {
    try {
      setLoading(true);
      const response = await PortfolioRelationshipServices.getAllNode(filters);
      const newData = response.data.data;
      dispatch({ type: "SET_PORTFOLIO_RELATIONSHIPS", payload: newData });
    } catch (error) {
      console.log("Error fetching data:", error);
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const portfolioData = useMemo(() => {
    if (portfolioSettingState?.data?.length && moduleName) {
      const portfolioValues = deepFindByName(
        portfolioSettingState?.data,
        moduleName,
        false
      );
      if (portfolioValues?.portfolioSections?.length) {
        if (portfolioValues?.moduleName) {
          dispatchTag({ type: "EMPTY_TAGS" });
          dispatchTag({
            type: "SET_MODULE_NAME",
            payload: portfolioValues?.moduleName,
          });
        }
        setSelectedFilters({});
        setShowFilter(false);
        return getSelectProperties(portfolioValues?.portfolioSections, true);
      }
    }

    // setLoading(false);
    // setPortfolioSettingData(portfolioSettingState?.data);
    // setSelectedCollapse();

    return [];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moduleName, portfolioSettingState?.data]);

  const fetchFilteredData = async (filter) => {
    try {
      setLoading(true);
      if (Object.keys(removeEmptyKeys(filter))?.length > 0) {
        // setList([])
        const portfolio = await deepFindByName(
          portfolioSettingState?.data,
          moduleName,
          false
        );
        const updatedFilter = {
          moduleName: portfolio?.moduleName || "",
          ...filter,
        };

        const response = await PortfolioSettingServices?.getFilteredPortfolios(
          updatedFilter,
          page,
          limit
        );
        if (response?.data?.data?.length) {
          setTimeout(() => {
            setLoading(false);
            setListApplication(
              response?.data?.data?.map((item) => ({
                ...item,
                moduleName:
                  transformText(
                    portfolio?.moduleName.replace("_", " "),
                    "pascal"
                  ) ||
                  transformText(moduleName.replace("_", " "), "pascal") ||
                  moduleName,
              })) || []
            );
            setTotalRecords(response?.data?.totalCount || 0);
          }, 500);
        } else {
          //message.info("No records found.");
          setfreeText("No items found.");
          setListApplication(
            response?.data?.data?.map((item) => ({
              ...item,
              moduleName:
                transformText(
                  portfolio?.moduleName.replace("_", " "),
                  "pascal"
                ) ||
                transformText(moduleName.replace("_", " "), "pascal") ||
                moduleName,
            })) || []
          );
          setTotalRecords(response?.data?.totalCount || 0);
        }
        console.log("response-filter-result", response);
      } else {
        setListApplication(listApplication);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      message.error("Failed to fetch data. Please try again.");
    } finally {
      setFilterSubmit(false);
      setLoading(false);
    }
  };

  // Filter and debounce
  const debouncedFilter = useDebounce(selectedFilters, debounceTimeOut);

  useEffect(() => {
    if (filterSubmit) {
      fetchFilteredData(removeEmptyArrObjOrval(debouncedFilter));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSubmit, debouncedFilter]);

  const handlePanelChange = useCallback((activeKeys) => {
    setactivePanel(activeKeys);
    localStorage.setItem("activePanelConnection", activeKeys);
  }, []);

  const ListItems = ({ item, isLast, isFirst, moduleName }) => {
    const isChildType = item?.type === 'Child';
	const isSourceType = item?.type === 'Source';
    const isTargetType = item?.type === 'Target';

    const handleCheckboxChange = (e) => {
      const { checked } = e.target;
      setCheckedList((prev) => {
        if (checked) {
          return [...prev, item.relationShipId];
        } else {
          return prev.filter((itemId) => itemId !== item.relationShipId);
        }
      });
    };

    return (
      <div className={`${isFirst && "mt-2"} `}>
        <div className="detailslist-bx">
        <Checkbox key={item.relationShipId} value={item.relationShipId} onChange={handleCheckboxChange} checked={checkedList.includes(item.relationShipId)} disabled={isChildType || isSourceType || isTargetType}>
          <div
            onClick={() => {
              let newLink = getMenuPortfolio({
                routeModuleName: moduleName,
                getRouteByRouteModule: true,
              });
              if (newLink) {
                // navigate(newLink + "/" + item?.id, {
                //   state: {
                //     from: location?.pathname,
                //     activeTabKey: "tab_connections",
                //   },
                // });
                let win = window.open(newLink + "/" + item?.id, "_blank");
                if (win) {
                  // Browser has allowed it to be opened
                  win.focus();
                }
              }
            }}
            className="cursor-pointer"
          >
            <div className="h5">
              {/* {item?.levelID || item?.referenceID || "-"}{" "}
                  {item?.displayName || "-"} */}
              {item?.levelid || item?.referenceid || ""}{" "}
              {item?.displayname || "-"}
            </div>
            <p className="whitespace-normal break-words">{item.description}</p>
          </div>
          </Checkbox>
          <div>
            {checkRole("delete") && (
              <Tooltip title={isChildType 
                ? `It's not a direct relation. It is derived from one of its child relations. You can't unlink it from here!`
                : isSourceType 
                ? `It's not a direct relation. It is derived from source app in integration. You can't unlink it from here!`
                : isTargetType 
                ? `It's not a direct relation. It is derived from target app in integration. You can't unlink it from here!`
                : `Unlink`} placement="right">
                <Button
                  type="default"
                  icon={<i className="fas fa-unlink"></i>}
                  className="my-0"
                  danger
                  onClick={() => {
                    if (!isChildType && !isSourceType && !isTargetType) {
                      handleRemoveRelationship({
                        id: item.relationShipId,
                        moduleName,
                        uniqueid: item.id,
                      });
                    }
                  }}
                  aria-label="Unlink item"
                  disabled={isChildType || isSourceType || isTargetType}
                />
              </Tooltip>
            )}
          </div>
        </div>

        {/* <Divider className="my-4 bg-[#f7f7f7]" /> */}
      </div>
    );
  };

  return (
    <div>
      <div className="card card-body relationdetail_bx">
         
        {portfolioSettingData?.connectedNodes
          ?.sort((a, b) => a?.name.localeCompare(b?.name))
          .map((rec, index) => {
            let portfolio_tmp = "";
            portfolio_tmp = transformText(
              rec?.moduleName?.replace("_", " "),
              "pascal"
            );
            // if (
            //   portfolio_tmp === "BusinessApplication" &&
            //   portfolioSettingData?.moduleName === "integration"
            // ) {
            //   return <> </>;
            // }
            
            const moduleAll = getNodesByModuleName(data?.relations, portfolio_tmp)?.tags
            const moduleAll_Parent = moduleAll?.filter((itm)=>itm.type === "Parent")
            let cnt = moduleAll?.length || 0; 
            //const moduleAllIds = moduleAll?.map((item)=>item.relationShipId)
            const moduleAllIds = moduleAll_Parent?.map((item)=>item?.relationShipId)
            //console.log('testing moduleAllIDs>>>>>>>>>>>>>>>>>>>>>',moduleAll_Parent);
            
            const filterDeleteValues = moduleAllIds?.filter(value => checkedList?.includes(value));

            const checkAllValues = () => {
              if (checkedList.length === moduleAllIds.length) {
                // Check if both arrays have the same values
                const allValuesMatch = checkedList.every(value => moduleAllIds?.includes(value));
                
                if (allValuesMatch) {
                  setCheckedList([]);
                } else {
                  setCheckedList([...moduleAllIds]);
                }
              } else {
                setCheckedList([...moduleAllIds]);
              }
            };
            

            const handleRemoveBulkRelationship = async (ids) => {
              try {
                Modal.confirm({
                  centered: true,
                  title: "Do you want to unlink this item?",
                  className: "modal-justify-start",
                  icon: <ExclamationCircleFilled />,
                  onOk: async () => {
                    try {
                      await PortfolioRelationshipServices.deletePortfolioBulkRelationship(ids);
                      await fetchDataAfterOperation();

                      if (scenarioId) {
                        const objdata = {
                          source_id: modelIDScenario, 
                          modulename: portfolioSettingData?.moduleName,
                          tab_name: "Relation",
                        };
                  
                        const obj=  await ScenarionServices.checkscenariomodifyrecord(objdata);
                          
                      }
                    } catch (error) {
                      console.error("Error removing relationship:", error);
                    }
                  },
                  onCancel() {
                    // console.log('Cancel');
                  },
                });
              } catch (error) {
                console.error("Error removing relationship:", error);
              }
            };


            return (
              <React.Fragment key={Math.random()}>
                <Collapse
                  defaultActiveKey={
                    selectedCollapse === index && `${selectedCollapse}`
                  }
                  expandIconPosition="start"
                  bordered={true}
                  className="settings-menus"
                  activeKey={activePanel}
                  onChange={handlePanelChange}
                >
                  <Panel
                    key={index}
                    header={moduleAll_Parent && moduleAll_Parent.length>0 ? <Checkbox indeterminate={moduleAllIds?.some(id => checkedList?.includes(id)) && !moduleAllIds?.every(id => checkedList?.includes(id))} checked={moduleAllIds?.every(id => checkedList?.includes(id))} onChange={checkAllValues}>{`${rec?.name}    (${cnt})  `}</Checkbox>:`${rec?.name}    (${cnt})  `}
                    style={{ width: "100%" }}
                    className="panel"
                    extra={
                      checkRole("add") && (
                        <div style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
                          {checkedList?.some(id => moduleAllIds?.includes(id)) &&  <Button
                            type="primary"
                            icon={<DeleteOutlined />}
                            onClick={async () => {
                              
                              //console.log("testing checkbox values>>>>>>>>>>>>>>>>filterDeleteValues",filterDeleteValues);
                              
                              await handleRemoveBulkRelationship(filterDeleteValues)
                            }}
                          />}
                        <Button
                          type="primary"
                          icon={<PlusOutlined />}
                          onClick={async () => {
                            addedPortfolioList(rec?.name);
                            setmoduleName(rec?.name);
                            addedPortfolioList(rec?.moduleName);

                            setmoduleNameTemp(rec?.moduleName);
                            setmodelNameTemp(portfolio_tmp);

                            setShowApplicationListModal(
                              !showApplicationListModal
                            );
                            setPage(1);
                            await getBusinessPortfolioList_Modulewise({
                              moduleName: rec?.moduleName,
                              modelName: portfolio_tmp,
                            });
                          }}
                        />
                        </div>
                      )
                    }
                  >
                    {(data &&
                      data?.relations?.length &&
                      Object.keys(
                        getNodesByModuleName(data?.relations, portfolio_tmp)
                      ).length > 0 &&
                      getNodesByModuleName(data?.relations, portfolio_tmp)?.tags
                        ?.length &&
                      /*getNodesByModuleName(data?.relations, portfolio_tmp)
                        ?.tags?.sort((a, b) =>
                          a.displayname.localeCompare(b.displayname)
                        )
                        .map((item, index) => {
                          const newItem = {
                            ...item,
                            title: item.displayname,
                            description: item.description,
                          };*/
                      getNodesByModuleName(data?.relations, portfolio_tmp)
                      ?.tags?.sort((a, b) => {
                        if (a.type === 'Parent' && b.type !== 'Parent') return -1;
                        if (a.type !== 'Parent' && b.type === 'Parent') return 1;
                        return 0;
                      })
                      .map((item, index) => {
                        const newItem = {
                          ...item,
                          title: item.displayname,
                          description: item.description,
                        };

                          return (
                            <ListItems
                              key={index}
                              item={newItem}
                              // isLast={index === conBc.length - 1}
                              // isFirst={index === 0}
                              moduleName={portfolio_tmp}
                            />
                          );
                        })) || <div></div>}
                  </Panel>
                </Collapse>
              </React.Fragment>
            );
          })}
        <hr />
      </div>

      <Modal
        title={
          <div className="d-flex justify-content-between popup_headerbx">
            <div>
              <PageTitleHeading
                text={moduleName + " List"}
                className={`my-0`}
              />
            </div>
            <div></div>
          </div>
        }
        centered
        open={showApplicationListModal}
        onOk={() => setShowListModal(!showListModal)}
        onCancel={() => {
          setShowListModal(!showListModal);
          setPage(1);
          setLimit(default_pageSize);
          setSelectedFilters({});
          setSelectedRelationFilters({});
          setShowApplicationListModal(!showApplicationListModal);
        }}
        width={window.innerWidth - 400}
        footer={false}
        closeIcon={true}
      >
        <div className="d-flex justify-content-between modelpopup_bx">
          <div id="portfolioList" className="container-fluid popup_leftpnlbx">
            {/* <Row gutter={[8, 8]} className="mx-0 text-left mt-2">
              <Col span={24} className="p-2"> */}
            <Spin className="loading_bx" size="small" spinning={loading}>
              {/* <div className="overflow-y listview_bx" style={{ maxHeight: modalContentHeight }}> */}
              <div className="overflow-y listview_bx">
                <BusinessApplicationListCards
                  cardModuleName={moduleName?.replace(" ", "")}
                  moduleName={moduleNameTemp}
                  filterLevel={filterLevel}
                  filterText={filterText}
                  filterBy={filterBy}
                  lists={listApplication}
                  addRemove={true}
                  setAddedData={setAddedData}
                  addedData={addedData}
                  handleAddRelationship={handleAddRelationship}
                  handleRemoveRelationship={handleRemoveRelationship}
                  dataLoading={dataLoading}
                  freeText={freeText}
                />
              </div>
              {totalRecords > 0 && (
                <Pagination
                  className="mt-2 text-right"
                  total={totalRecords}
                  showTotal={(total) => `Total ${total} items`}
                  defaultPageSize={default_pageSize}
                  defaultCurrent={page}
                  pageSizeOptions={default_pageSizeOptions}
                  showSizeChanger
                  onChange={(p, ps) => {
                    setFilterSubmit(true);
                    setLimit(ps);
                    setPage(p);
                    console.log("Page...", p);
                    console.log("PageLimit...", ps);
                  }}
                />
              )}
            </Spin>
            {/* </Col>
            </Row> */}
          </div>
          <div className="popup_rightpnlbx">
            {/* filter  */}
            <FilterRightSideBar
              activeGridStyle={1}
              selectedFilters={selectedFilters}
              portfolioData={portfolioData || []}
              setShowFilter={setShowFilter}
              showFilter={showFilter}
              setSelectedFilters={(value) => {
                setPage(1);
                setSelectedFilters(value);
              }}
              allowSlider={false}
              optionalLevel={optionalLevelPortfoliosModule.includes(moduleNameTemp)}
              //Filter relations
              filterSubmit={filterSubmit}
              setFilterSubmit={(value) => {
                setPage(1);
                setFilterSubmit(value);
              }}
              selectedRelationFilters={selectedRelationFilters}
              setSelectedRelationFilters={setSelectedRelationFilters}
              isRelationPopup={showApplicationListModal}
              isRelationModuleName={moduleName}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default PortFolioItems;

const optionalLevelPortfoliosModule = ["apqcs_processes","business_capability","business_processes"];
