import React, { useEffect, useState, useRef } from "react";
import "./CompareScenarioV2.scss";
import { Divider, Flex, Spin } from "antd";
import { PageTitleHeading } from "../../utils/Common";
import CompareScenarioForm from "./compare/CompareScenarioForm";
import ScenarioServices from "../../services/services/ScenarioServices";
import TextWithTooltip from "../../utils/TextWithTooltip";
import LoadingBar from 'react-top-loading-bar'

const CompareScenarioV2 = (props) => {
  const ref = useRef(null)
  const [loading, setLoading] = useState(true);
  const [scenarioList, setScenarioList] = useState([]);

  useEffect(() => {
    getScenarioList();
  }, []);

  const getScenarioList = async (page = 1, pageSize = 20, search = "") => {
    setLoading(true);
    if (ref.current) {
      ref.current.continuousStart();
  }
    let obj = {
      search: search,
      page: 1,
      limit: 300,
      isScenarioType: false,
      isUser: false,
    };
    try {
      let response = await ScenarioServices?.getAllScenariosV2(obj);
      if (response?.data?.data) {
        setScenarioList(response?.data?.data);
      }
      setLoading(false);
      if (ref.current) {
        ref.current.complete();
    }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      if (ref.current) {
        ref.current.complete();
    }
    }
  };
  // const getScenarioList = async () =>{
  //     let obj= {};
  //     await ScenarioServices.getScenarioList(obj)
  //     .then((response) => {
  //         if (response?.data?.code === 200) {
  //             let responseVal = response?.data?.data;
  //             if(responseVal){
  //                 setScenarioList(responseVal);
  //             }
  //         }
  //         setLoading(false);
  //     })
  //     .catch((err) => {
  //         // console.log(err.message);
  //         setLoading(false);
  //     });
  // }
  return (
    <>
      <LoadingBar color='#1f1f1f' ref={ref} />
      <div className="compare-scenario-v2 container-fluid">
        <Spin className="loading_bx" size="small" spinning={loading}>
          <div className="headingbx">
              <Flex justify='space-between' align='center' gap={4} className='w-100'>
                  <PageTitleHeading text={"Compare Scenario"} />
              </Flex>
          </div>
          <CompareScenarioForm
            scenarioList={scenarioList}
            setLoading={setLoading}
            loading={loading}
            list={scenarioList}
          />

          {/* <div className='card border-0 bg-gray-1'>
                      <div className='card-body'>
                          <div className='h5'>Continue With Previous comparison</div>
                          <div className="cu-prev-comparison" style={{maxWidth:window?.innerWidth-300}}>
                              <div className='border-dashed text-center card card-body cu-prev-comparison-card'>
                                  <h6><TextWithTooltip text={`Master`} characterLimit={20}/></h6>
                                  <small>Vs</small>
                                  <h6><TextWithTooltip text={`VCCH`} characterLimit={20}/></h6>
                              </div>
                              <div className='border-dashed text-center card card-body cu-prev-comparison-card'>
                                  <h6><TextWithTooltip text={`VCCH`} characterLimit={20}/></h6>
                                  <small>Vs</small>
                                  <h6><TextWithTooltip text={`VCK`} characterLimit={20}/></h6>
                              </div>
                          </div>
                      </div>
                  </div> */}
        </Spin>
      </div>
    </>
  );
};

CompareScenarioV2.propTypes = {};

export default CompareScenarioV2;
