import { Button, Flex, Spin, Tooltip } from 'antd';
import React, { useCallback, useState } from 'react'
import { useEffect } from 'react';
import { default_pageSizeOptions, PageTitleHeading } from '../../utils/Common';
import ChnageLogsServices from '../../services/services/ChangeLogsServices';
import moment from 'moment';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import exportExcel from '../../utils/exportExcel';
import TableFilter from './activity-logs/TableFilter';
import ActivityTable from './activity-logs/ActivityTable';
import PDFTable from './activity-logs/PDFTable';
import { columns, getDateTime } from './activity-logs/ActivityFun';

const ActivityLogs = ({pageTitle=""}) => {
    const [tableProps,setTableProps] = useState({
        columns,
        search:{
            value:'',
        },
        length: 10,
        start: 1,
        order:[
            {
                "column": columns?.findIndex(f=>f.dataIndex==="createdAt"),
                "order": "descend",
                "field": "createdAt",
                "columnKey": "createdAt",
                "dir": "DESC"
            },
            {
                "column": 0,
                "order": "ascend",
                "field": columns[0]?.dataIndex,
                "columnKey": columns[0]?.dataIndex,
                "dir": "ASC"
            }
        ],
    });
    const [totalRecords,setTotalRecords] = useState(0);
    const [tableData,setTableData] = useState([]);
    const [users,setUsers] = useState([]);
    const [loading,setLoading] = useState(false);
    const [pageLoading,setPageLoading] = useState({spinning:false});
    const [exportPDFLoading,setExportPDFLoading] = useState(false);
    const [pageSizeOptions,setPageSizeOptions] = useState(default_pageSizeOptions || []);
    
    const fetchActivityData = useCallback(async () => {
        try {
            setLoading(true);            
            setTotalRecords(0);
            setTableData([]);
            let response = await ChnageLogsServices.getUserActivityLogs(tableProps);
            if(response?.data?.recordsTotal){
                setTotalRecords(response?.data?.recordsTotal);
                setTableData(response?.data?.data);
                let psoptions = [...default_pageSizeOptions,Number(response?.data?.recordsTotal)];
                psoptions = [...new Set(psoptions)];
                psoptions.sort(function(a, b) { return a - b; });
                setPageSizeOptions(psoptions);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }, [tableProps]) // if userId changes, useEffect will run again

    const fetchUsers = useCallback(async () => {
        try {
            setLoading(true);
            let response = await ChnageLogsServices.getUsers();
            if(response?.data?.data){
                setUsers(response?.data?.data);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }, []) 
    
    useEffect(()=>{
        fetchActivityData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[fetchActivityData]);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers])

    const setTablePropsData = ({key,value,...props}) => {
        if(key){
            setTableProps({
                ...tableProps,
                [key]: value || "",
            })
        }else{
            Object.entries(props?.search || {}).forEach(([key,value])=>{
                if(key!=="value"){
                    if(typeof value === "object" && value?.length===0){
                        delete props?.search[key];
                    }
                    if(value === ""){
                        delete props?.search[key];
                    }
                }
            })
            
            setTableProps({
                ...tableProps,
                ...props
            })
        }
    }

    const recordExportExcel = async () =>{        
        try {
            setPageLoading({
                spinning: true,
                percent: 'auto',
                tip: 'Please wait...\n',
                indicator: <LoadingOutlined spin />,
            });
            let response = await ChnageLogsServices.getUserActivityLogs({
                ...tableProps,
                start:1,
                length: totalRecords,
            });
            if(response?.data?.data){
                exportExcel({
                    name:'UserActivity-'+moment().format('DD-MM-YY Hmmss'),
                    records: response?.data?.data?.map(record=>{
                        let cols ={};
                        columns.forEach(col=>{
                            if(col?.dataIndex === "createdAt" || col?.dataIndex === "updatedAt" ){
                                cols[col?.title]=getDateTime(record[col?.dataIndex]);
                            }else{
                                cols[col?.title]=record[col?.dataIndex];
                            }                                            
                        })
                        return cols;
                    }),
                });
            }
        } catch (error) {
            console.error("error :",error)
        } finally {
            setPageLoading({
                spinning: false,
            });
        }
    }

    const recordExportToPDF = () => {
        try {
            setExportPDFLoading(true);
            
        } catch (error) {
            console.error("error: ",error);            
        } finally {
            // setExportPDFLoading(false);
        }
    };

    return (
        <div className='container-fluid'>
            <Spin {...pageLoading}>
                <Flex gap={4} vertical>
                    <div className="headingbx">
                        <Flex justify='space-between' align='center' gap={4} className='w-100'>
                            <PageTitleHeading text={pageTitle} />
                            <Flex gap={8}>
                                <Tooltip title={`Export Xlsx`}>
                                    <Button 
                                        icon={<DownloadOutlined />}
                                        onClick={()=>{
                                            recordExportExcel();
                                        }}
                                    />
                                </Tooltip>
                                {/* <Tooltip title={`Export PDF`}>
                                    <Button 
                                        icon={<DownloadOutlined />}
                                        onClick={()=>{
                                            recordExportToPDF();
                                        }}
                                    />
                                </Tooltip> */}
                            </Flex>
                        </Flex>
                    </div>
                    <TableFilter
                        tableProps={tableProps}
                        tableData={tableData}
                        setTablePropsData={setTablePropsData}
                        users={users}
                    />
                    <ActivityTable
                        loading={loading}
                        tableData={tableData}
                        tableProps={tableProps}
                        columns={columns}
                        pageSizeOptions={pageSizeOptions}
                        totalRecords={totalRecords}
                        setTablePropsData={setTablePropsData}
                    />
                    {exportPDFLoading && <PDFTable
                        tableProps={tableProps}
                        totalRecords={totalRecords}
                        generatePDF={setExportPDFLoading}
                    />}
                </Flex>
            </Spin>
        </div>
    )
}

export default ActivityLogs;