import "./IntegrationExtraForm.scss";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Flex, Form, Input, Layout, Modal, Pagination, Row, Spin, Tooltip, message, Checkbox, Select, Typography, Divider } from "antd";
import React, { useEffect, useRef, useState } from "react";
import TextWithTooltip from "../../../../utils/TextWithTooltip";
import { ExclamationCircleFilled } from "@ant-design/icons";
import {
    PageTitleHeading,
    debounceTimeOut,
    deepFindByName,
    default_pageSize,
    default_pageSizeOptions,
    getSelectProperties,
    removeEmptyArrObjOrval,
    removeEmptyKeys,
    transformText
} from "../../../../utils/Common";
import { getMenuPortfolio, staticSettingPortfolioList } from "../../../../utils/settingCommon";
import { usePortfolioSetting } from "../../../../Context/portfolioSettingProvider";
import PortfolioSettingServices from "../../../../services/services/PortfolioSettingsService";
import useDebounce from "../../../../helper/useDebounce";
import usePortfolioFetcher from "../../../../hooks/usePortfolioFetcher";
import { usePortfolioTag } from "../../../../Context/portfolioTagProvider";
import useFetchPortfolioTag from "../../../../hooks/useFetchPortfolioTag";
import BusinessProcessListContent from "../../../businessProcess/list/BusinessProcessListContent";
import BusinessProcessListFilter from "../../../businessProcess/list/BusinessProcessListFilter";
import { default_data_lists, default_technology_lists } from "./IntegrationCommon";
import ModuleDropdown from "../bulkentry/ModuleDropdown";


const IntegrationExtraForm = ({setFormData,formData,...propsIntegration}) => {

    const CheckboxButtons = ({label,...props}) =>{
        const moduleData = getMenuPortfolio({routeModuleName:"BusinessApplication"})
        const [openPortfolio, setOpenPortfolio] = useState(false);
    
        return <React.Fragment>
            <Button
                type="primary"
                shape="circle"
                icon={<PlusOutlined />}
                onClick={() => {
                    setOpenPortfolio(true);
                }}
                ghost
            />
            
            {
                openPortfolio && 
                <React.Fragment>
                    <ModalCheckboxList 
                        open={openPortfolio} 
                        setOpen={setOpenPortfolio} 
                        moduleName={moduleData?.moduleName} 
                        modalTitle={label} 
                        modalWidth={400} 
                        form={propsIntegration?.form}
                        {...props}
                    />
                </React.Fragment>
            }
        </React.Fragment>
    }

    return (
        <React.Fragment>
            {/* <ModuleDropdown moduleName="dataobject" FormItemProps={{wrapperCol:{span:12},labelCol:{ span: 8 },label:"Data Object",colon:false,initialValue:formData['dataobject']}} form={propsIntegration?.form} FormItemClassName="mb-2"/> */}
            {/* <ModuleDropdown moduleName="business_processes" FormItemProps={{wrapperCol:{span:12},labelCol:{ span: 8 },label:"Business Process",colon:false,initialValue:formData['dataobject']}} form={propsIntegration?.form} FormItemClassName="mb-2"/> */}
            {/* <Divider /> */}
            <FieldsetSelect name={'sourceApp'} label={"Source App"} Buttons={ApplicationsButtons} setFormData={setFormData} formData={formData} {...propsIntegration}/>
            <FieldsetSelect name={'targetApp'} label={"Target App"} Buttons={ApplicationsButtons} setFormData={setFormData} formData={formData} {...propsIntegration}/>
            {/* <FieldsetSelect name={'data'}       label={"Data"}          Buttons={CheckboxButtons} setFormData={setFormData} formData={formData} defaultLists={default_data_lists}/> */}
            {/* <FieldsetSelect name={'technology'} label={"Technology"}    Buttons={CheckboxButtons} setFormData={setFormData} formData={formData} defaultLists={default_technology_lists}/> */}
        </React.Fragment>
    );
};

IntegrationExtraForm.defaultProps = {};

export default IntegrationExtraForm;

const ApplicationsButtons = ({label,...props}) =>{
    const moduleData = getMenuPortfolio({routeModuleName:"BusinessApplication"})
    const [openPortfolio, setOpenPortfolio] = useState(false);
    return <React.Fragment>
        <Button
            type="primary"
            shape="circle"
            icon={<PlusOutlined />}
            onClick={() => {
                setOpenPortfolio(true);
            }}
            ghost
        />
        
        {
            openPortfolio && 
            <React.Fragment>
                <ModalPortFolioList 
                    open={openPortfolio} 
                    setOpen={setOpenPortfolio} 
                    moduleData={moduleData}
                    moduleName={moduleData?.moduleName} 
                    modalTitle={label} 
                    modalWidth={window?.innerWidth-200} 
                    form={props?.form}
                    {...props}
                />
            </React.Fragment>
        }
    </React.Fragment>
}

export const FieldsetSelect = ({ name,label, lists, modalWidth ,Buttons,setFormData,formData,defaultLists,...props}) => {
    const [selectedLists,setSelectedList] = useState([]);

    useEffect(()=>{
        // console.log("selectedLists",selectedLists);
        let fieldValues = selectedLists?.map(item=>{ return item?.id; });
        let fieldValues2 = selectedLists?.map(item=>{ 
            return {
                id:item?.id,
                displayname:item?.displayname,
                selectedConnection:item?.selectedConnection
            }
         });
        setFormData(name, fieldValues)
        setFormData(name+'Connection', fieldValues2)        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedLists]);

    useEffect(() => {
        setSelectedList([...formData[name+'Connection'] || []]);
    }, [name, formData]);

    const SelecteApp = (item) =>{
        // setSelectedList([item]);
        if(name==="sourceApp"){
            setSelectedList([item]);
            // setSelectedList([...selectedLists,item]);
        }else{
            setSelectedList([...selectedLists,item]);
        }
        message.success(`${item?.displayname || ""} added successfully.`)
    }

    const SelecteApps = (items) =>{
        setSelectedList([...items]);
    }

    const removeItem = (item) =>{
        
            const id = item?.id || 0;
            Modal.confirm({
                title: 'Are you sure want to delete?',
                icon: <ExclamationCircleFilled />,
                async onOk() {
                    defaultLists = defaultLists?.map(el=>{ if(el?.id?.toString()===item?.id?.toString()){ el.checked=false } return el;})
                        let lists = selectedLists?.filter(f=>f?.id?.toString()!==item?.id?.toString());
                        setFormData(name, lists)
                        setSelectedList(lists);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        

        
    }

    let defaultSpan = 6;
    let span = selectedLists?.length > 0 ? (selectedLists?.length%24===0 ? 24:defaultSpan):24;

    return (
        <React.Fragment>
            <Form.Item name={name} initialValue={selectedLists?.map(item=>{ return item?.id; })} hidden><Input /></Form.Item>
            <Form.Item name={name+'Connection'} initialValue={selectedLists?.map(item=>{  return { id:item?.id, selectedConnection:item?.selectedConnection }  })} hidden><Input /></Form.Item>
            <div className="cu-fieldset mb-3">
                <fieldset className="fieldset">
                    <label className="fieldset-label">{label}</label>
                    <div className="m-3">
                        <Row gutter={[16, 16]} justify="start" className="justify-left">
                            <ListSelectedItems lists={selectedLists} keyName={name} removeItem={removeItem} span={defaultSpan}/>
                            <Col span={span} className="text-center">
                                <Tooltip title={label}>
                                    <Buttons 
                                        label={label} 
                                        SelecteApp={SelecteApp} 
                                        selectedApps={selectedLists} 
                                        SelecteApps={SelecteApps} 
                                        defaultLists={defaultLists}
                                        name={name}
                                        form={props?.form}
                                    />
                                </Tooltip>
                            </Col>
                        </Row>
                    </div>
                </fieldset>
            </div>
            
        </React.Fragment>
    );
};

FieldsetSelect.defaultProps = {
    label: "",
    modalWidth: window?.innerWidth - 200,
    selectCheckbox:false,
    defaultLists:[],
};


export const ListSelectedItems = ({ lists, keyName, span, removeItem }) => {
    return lists?.map((item) => {
        let title = item?.displayname || item?.label;
        return (
            <Col key={Math.random()} span={span || 24}>
                <div className="form-control">
                    <Flex gap={8} justify="space-between">
                        <div style={{maxWidth:"75%"}}>
                            <TextWithTooltip
                                text={title}
                                characterLimit={'auto'}
                                isTooltip
                            />
                        </div>
                        <div style={{width:"25%",textAlign:"end"}}>
                            <DeleteOutlined
                                className="cursor-pointer ml-2 text-danger"
                                onClick={()=>{removeItem(item)}}
                                style={{ fontSize: "20px" }}
                            />
                        </div>
                    </Flex>
                </div>
            </Col>
        );
    });
};

export const ModalPortFolioList = ({
    moduleName,
    modalTitle,
    modalWidth,
    maxHeight,
    open,
    setOpen,
    selectedApps,
    SelecteApp,
    form,
    ...props
}) => {

    const [showFilter, setShowFilter] = useState(true);
    const [portfolioData, setPortfolioData] = useState([]);
    const { state: portfolioSettingState, dispatch: portfolioSettingDispatch } = usePortfolioSetting();
    const { loading: PortfolioLoading, fetchPortfolio } = usePortfolioFetcher();
    const [selectedFilters, setSelectedFilters] = useState({});
    const [lists,setLists] = useState([]);
    const [loading,setLoading] = useState(true);
    const [filterChanges, setFilterChanges] = useState(false);

    // Submit filter state it should work only apply is clicked
    const [filterSubmit, setFilterSubmit] = useState(false);
    const [selectedRelationFilters, setSelectedRelationFilters] = useState({});

    const [totalRecords, setTotalRecords] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(default_pageSize);
    const { state: stateTag, dispatch: dispatchTag } = usePortfolioTag();
    const { loading: tagLoading, error: tagError } = stateTag;
    const { error: errorTag, fetchTagsData } = useFetchPortfolioTag();

    useEffect(() => {
        if (portfolioSettingState?.data?.length) {
            const portfolioValues = deepFindByName( portfolioSettingState?.data, staticSettingPortfolioList?.application, false );
            
            if (portfolioValues?.portfolioSections?.length) {
                if (portfolioValues?.moduleName) {
                //   dispatchTag({ type: "EMPTY_TAGS" });
                //   dispatchTag({
                //     type: "SET_MODULE_NAME",
                //     payload: portfolioValues?.moduleName,
                //   });
                }
                setPortfolioData(getSelectProperties(portfolioValues?.portfolioSections, true));
            } else {
                setPortfolioData([]);
            }
        } else {
            fetchAllPortfolio();
        }
         
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [portfolioSettingState?.data]);

    const fetchAllPortfolio = async () => {
        try {
          const response = await fetchPortfolio(portfolioSettingDispatch, 1, 100);
          if (response) {
            // message.success("Portfolio fetched successfully.");
          } else {
            message.error("Portfolio not found.");
          }
        } catch (error) {
          console.error("Error fetching portfolio:", error);
          message.error("Something went wrong.");
        }
    };
 
    const Buttons = (item) => {
        const [openType,setOpenType] = useState(false);
        let list = selectedApps?.find(f=>f?.id?.toString()===item?.item?.id?.toString())

        let disabled = DisableItemClass({item:item?.item})?.disabled || false;

        if(list && !disabled){
            return <Button disabled>Added</Button>
        }else{
            return <React.Fragment>
                <Button 
                    type="primary" 
                    icon={<PlusOutlined/>} 
                    onClick={()=>{ 
                        // setOpenType(true);
                        SelecteApp(item?.item); 
                    }}
                    disabled={disabled}
                ></Button>
                <ApplicationTypeModal 
                    setOpen={setOpenType} 
                    open={openType} 
                    setSelectedItem={(value)=>{ SelecteApp({...item?.item,[`selectedConnection`]:value});  }} 
                    item={item?.item}
                />
            </React.Fragment>
        }
    }

    const DisableItemClass = ({item})=>{
        let sourceAppIds = form?.getFieldValue('sourceApp');
        let targetAppIds = form?.getFieldValue('targetApp');
        let className = '';
        let disabled = false;

        if(props?.name==='targetApp' && sourceAppIds?.includes(item?.id)){
            className = 'disable-item';
            disabled = true;
        }
        if(props?.name==='sourceApp' && targetAppIds?.includes(item?.id)){
            className = 'disable-item';
            disabled = true;
        }
        return {
            ...item,
            sourceAppIds,
            className,
            disabled,
        };
    }

    useEffect(() => {
        setLoading(true);
        fetchFilteredData(selectedFilters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [portfolioSettingState]);

    // Filter and debounce
    const debouncedFilter = useDebounce(selectedFilters, debounceTimeOut);
    useEffect(() => {
        if (filterSubmit) {
            fetchFilteredData(removeEmptyArrObjOrval(debouncedFilter));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterSubmit,debouncedFilter,page,limit]);

    //Fetch tags items
    const fetchAllPortfolioTags = async () => {
        try {
            await fetchTagsData();
        } catch (error) {
            console.error("Error fetching portfolio:", error);
            message.error("Something went wrong.");
        }
    };

    // useEffect(() => {
    //     if (stateTag?.moduleName) {
    //     fetchAllPortfolioTags();
    //     }
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [stateTag?.moduleName]);

    const fetchFilteredData = async (filter) => {
        try {
            setLoading(true);
            if (portfolioSettingState?.data?.length) {
                filter  = removeEmptyKeys(filter);
                const portfolio = await deepFindByName(
                    portfolioSettingState?.data,
                    staticSettingPortfolioList?.application,
                    false
                );
                const updatedFilter = {
                    moduleName: portfolio?.moduleName,
                    ...filter,
                };
                const response = await PortfolioSettingServices?.getFilteredPortfolios(updatedFilter,page,limit);
                if (response?.data?.data?.length) {
                    setTimeout(() => {
                        setLoading(false);
                        setLists(response?.data?.data);
                        setTotalRecords(response?.data?.totalCount || 0);
                    }, 500);
                } else {
                    //message.info("No records found.");
                    setTimeout(() => {
                        setLists(response?.data?.data);
                        setTotalRecords(response?.data?.totalCount || 0);
                        setFilterChanges(true)
                    }, 500);
                }
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setFilterSubmit(false);
            setLoading(false);
        }
    };
    
    return (
        <Modal
            title={
                <div className="d-flex justify-content-between">
                     {/* <div>
                        <PageTitleHeading
                            text={modalTitle + " List"}
                            className={`my-0`}
                        />
                    </div> */}
                   
                    {/* <div>
                        <Button
                            onClick={() => {
                                setPage(1);
                                setLimit(default_pageSize);
                                setSelectedFilters({});
                                setOpen(!open);
                            }}
                            className="mr-2 ml-3"
                        >
                            Close
                        </Button>
                    </div> */}
                </div>
            }
            centered
            open={open}
            onOk={() => setOpen(!open)}
            // onCancel={() => setOpen(!open)}
            onCancel={() => {
                setPage(1);
                setLimit(default_pageSize);
                setSelectedFilters({});
                setOpen(!open);
            }}
            width={window.innerWidth*75/100}
            footer={false}
            closeIcon={true}
        >
            <Layout className="modelpopup_bx">
                <Layout.Content className='popup_leftpnlbx'>
                    <Spin className="loading_bx" size="small" spinning={loading}>
                        <div className='container-fluid'>
                            <div className="popup_headerbx">
                                <div className="text-start h2 my-0">
                                    {modalTitle} List 
                                    <small className="text-danger">
                                        {
                                            ((()=>{
                                                if(props?.name==="sourceApp"){
                                                    return `(Only single selection is allowed here)`;
                                                }else{
                                                    return `(Multiple selection is allowed here)`;
                                                }
                                            })())
                                        }
                                    </small>
                                </div>
                            </div>
                            <div className='overflow-y listview_bx' style={{maxHeight:maxHeight}}>
                                <BusinessProcessListContent
                                    lists = {lists}
                                    ButtonComponents = {Buttons}
                                    loading={loading}
                                    moduleName={transformText(moduleName.replace("_", " "), "pascal")}
                                    DisableItemClass={DisableItemClass}
                                    basePath={props?.moduleData?.route}
                                />
                            </div>
                            {totalRecords>0 &&
                                <Pagination
                                    className="mt-2 text-right"
                                    total={totalRecords}
                                    showTotal={(total) => `Total ${total} items`}
                                    defaultPageSize={default_pageSize}
                                    defaultCurrent={page}
                                    pageSizeOptions={default_pageSizeOptions}
                                    showSizeChanger
                                    onChange={(p, ps)=>{
                                        setFilterSubmit(true);
                                        setLimit(ps)
                                        setPage(p);
                                    }}
                                />
                            }
                        </div>
                    </Spin>
                </Layout.Content>
                <Layout.Sider theme="light" width={(!showFilter)?50:240} className="popup_rightpnlbx">
                    <BusinessProcessListFilter
                        activeGridStyle={1}
                        selectedFilters={selectedFilters}
                        data={portfolioData || []}
                        setShowFilter={setShowFilter}
                        showFilter={showFilter}
                        setSelectedFilters={(value)=>{ setPage(1);setSelectedFilters(value); }}
                        allowSlider={false}
                        optionalLevel={
                          moduleName === staticSettingPortfolioList.application
                        }
                        // maxHeight={window.innerHeight-300}
                        // minHeight={window.innerHeight-300}
                        
                      
                        //Filter relations
                        filterSubmit={filterSubmit}
                        setFilterSubmit={(value)=>{setPage(1);setFilterSubmit(value)}}
                        selectedRelationFilters={selectedRelationFilters}
                        setSelectedRelationFilters={setSelectedRelationFilters}
                        isRelationPopup={true}
                        isSourceTargetModuleName={moduleName}
                   />
                   
                </Layout.Sider>
            </Layout>
        </Modal>
    );
};

ModalPortFolioList.defaultProps = {
    label: "",
    modalWidth: window?.innerWidth - 100,
    maxHeight: window?.innerHeight - 240,
    ExtraButtons:()=>{},
};


export const ModalCheckboxList = ({
    moduleName,
    modalTitle,
    modalWidth,
    maxHeight,
    open,
    setOpen,
    selectedApps:checkedList,
    SelecteApp,
    SelecteApps,
    defaultLists,
}) => {
    const [loading,setLoading] = useState(false);
    const [selectAll,setSelectAll] = useState(false);
    const [lists,setLists] = useState([]);
    
    useEffect(()=>{
        let tempLists = [...defaultLists];
        tempLists = tempLists?.map(el=>{
            el.id = el.value;
            return el;
        })
        setLists(tempLists);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[defaultLists])

    const handleCheck = (checked,item) =>{
        let tempLists = [];
        if(!checked){
            setSelectAll(false)
            
            
        }
        tempLists = lists?.map(el=>{
            if(el?.id?.toString()===item?.id?.toString()){
                el.checked = checked;
            }
            return el;
        })
        setLists(tempLists);
    }

    const updateSelectValue = () => {
        let tempLists = lists?.filter(f=>f.checked===true);
        if(selectAll){
            tempLists = tempLists?.map(el=>{
                el.checked = true;
                return el;
            })
        }

        SelecteApps(tempLists);
        setOpen(!open)
    }

    const SelectAllCheck = (checked) =>{
        setSelectAll(checked); 
        let tempLists = lists?.map(el=>{
            el.checked = checked;
            return el;
        })
        setLists(tempLists);
    }


    return (
        <Modal
            title={
                <div className="d-flex justify-content-between mb-2">
                    <div>
                        <PageTitleHeading
                            text={modalTitle + " List"}
                            className={`my-0`}
                        />
                    </div>
                    <div>
                        <Button type="link" onClick={() => { SelectAllCheck(!selectAll); }} className="mr-2 ml-3" >
                            {selectAll ? `Unselect All`:`Select All`}
                        </Button>
                    </div>
                </div>
            }
            centered
            open={open}
            onOk={() => updateSelectValue()}
            onCancel={() => {updateSelectValue()}}
            width={modalWidth}
            footer={false}
            closeIcon={true}
        >
            <Spin className="loading_bx" size="small" spinning={loading}>
                <Flex gap={4} vertical className="overflow-y pr-2" /*style={{maxHeight:maxHeight}}*/>
                    {
                        lists?.map(option=>{
                            let key = 'list-'+Math.random();
                            let parameters = {}
                            parameters.id=key;
                            parameters.checked = option?.checked;
                            if(selectAll){
                                parameters.checked = true;
                            }
                            parameters.value = option?.value || false;
                            
                            return <React.Fragment key={key}>
                                <label htmlFor={key} className='form-control'>
                                    <Flex gap={8} justify='space-between'>
                                        <div>{option?.label}</div>
                                        <div>
                                            <Checkbox 
                                                onChange={({target})=>{ 
                                                    handleCheck(target?.checked,option)
                                                }}
                                                {...parameters}
                                            />
                                        </div>
                                    </Flex>
                                </label>
                            </React.Fragment>
                        })
                    }
                </Flex>
            </Spin>
        </Modal>
    );
};

ModalCheckboxList.defaultProps = {
    label: "",
    modalWidth: 400,
    maxHeight: window?.innerHeight - 300,
    ExtraButtons:()=>{},
};

export const ApplicationTypeModal = ({open,setOpen,setSelectedItem}) => {
    return <Modal
        title={open?.DisplayName}
        open={open?true:false}
        closable
        onCancel={()=>{setOpen(null)}}
        footer={false}
        zIndex={10000}
    >
        <Form
            labelCol={{span:8}}
            wrapperCol={{span:16}}
            onFinish={(value)=>{ setSelectedItem(value) }}
            requiredMark={false}
        >
            <Flex gap={16} className="border p-3" vertical>
                <Form.Item name="connectionType" label={'Connetion Type'} rules={[{required:true,}]} className="mb-0">
                    <Select
                        defaultValue={""}
                        options={[
                            {value:"VCOM",label:"VCOM"},
                            {value:"VCCH",label:"VCCH"}
                        ]}
                    />
                </Form.Item>
                <Form.Item name="dataFormat" label={'Data Format'} rules={[{required:true,}]} className="mb-0">
                    <Select
                        defaultValue={""}
                        options={[
                            {value:"JSON",label:"JSON"}
                        ]}
                    />
                </Form.Item>
                <Button htmlType="submit" type="primary" className="w-100">Ok</Button>
            </Flex>
        </Form>
    </Modal>
}