import React, { useEffect, useRef, useState } from "react";

import {
  Button,
  Card,
  Flex,
  Form,
  Layout,
  message,
  Result,
  Select,
  Switch,
} from "antd";
import LoadingBar from "react-top-loading-bar";
import { PageTitleHeading } from "../../../utils/Common";
import CompareChart from "./CompareChart";
import ScenarioServices from "../../../services/services/ScenarioServices";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import ExpenseChart from "./ExpenseChart";
import { GoBack } from "../../../utils/GoBackHistory";

let keySession2 = 'compare-scenario-portfolio';
let keySession1 = 'compare-scenario';

function CompareScenarioV2() {
  const location = useLocation();
  const loadingBarRef = useRef(null);
  const [filterForm] = Form.useForm();
  const selectDefaultProps = {
    showSearch: true,
    style: { minWidth: "150px" },
    size: "medium",
    allowClear: true,
  };
  const [portfolioList, setPortfolioList] = useState([]);
  const [selectedPortfolio, setSelectedPortfolio] = useState([]);
  const [finalChartData, setFinalChartData] = useState([]);
  const [finalBarChartData, setBarFinalChartData] = useState([]);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(true);

  useEffect(() => {
    if (!location?.state) {
      location.state = JSON.parse(sessionStorage.getItem(keySession1)) || null;
      const compareScenarioPortfolioTemp = JSON.parse(sessionStorage.getItem(keySession2)) || [];
      setSelectedPortfolio(compareScenarioPortfolioTemp);
    } else {
      sessionStorage.setItem(keySession1, JSON.stringify(location?.state));
      setSelectedPortfolio([]);
    }

    getscenariocomparison(location.state);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    filterDataPortfoliowise();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPortfolio]);

  useEffect(() => {
    getUniquePortFolioFromData();
    filterDataPortfoliowise();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const getscenariocomparison = (comparisonState={}) => {
    const integer_array = comparisonState?.compare?.compareIds?.map(Number);

    const obj = {
      scenario_ids: integer_array,
    };

    // const obj = {
    //   scenario_ids: [346, 332],
    // };

    ScenarioServices.getscenariocomparison(obj)
      .then((result) => {
        if (result.status === 200) {
          if (result.data) {
            setData(result.data);
          }
        } else {
          message.error("error occured during getting daya");
        }
      })
      .catch((err) => {});
  };

  const getUniquePortFolioFromData = () => {
    const uniquePortfolios = Array.from(
      new Set(
        data?.flatMap((scenario) =>
          scenario?.portfolio?.map((portfolio) =>
            JSON.stringify({
              portfolioname: portfolio?.portfolioname,
              id: portfolio?.portfolio_id,
              color: portfolio?.color,
            })
          )
        )
      )
    )?.map((item) => JSON.parse(item));

    setPortfolioList(uniquePortfolios);
  };

  const filterDataPortfoliowise = () => {
    const dataFinal = [];
    let totalOpex = 0;
    let totalCapex = 0;
    let totalExpenditure = 0;
    let symbol = "";
    const scenarioData1 = data?.map((scenario) => {
      const scenarioData = data?.find((event) => event.name === scenario.name);

      totalOpex = 0;
      totalCapex = 0;
      totalExpenditure = 0;

      scenarioData.portfolio.forEach((portfolio) => {
        portfolio.data.forEach((milestone) => {
          totalOpex += parseFloat(milestone?.opex);
          totalCapex += parseFloat(milestone?.capex);
          totalExpenditure += parseFloat(milestone?.totalexp);
          symbol = milestone?.currency;
        });
      });

      dataFinal.push({
        type: scenarioData?.type,
        id: scenarioData?.id,
        name: scenarioData?.name,
        start: new Date(scenarioData?.start),
        end: new Date(scenarioData?.end),
        progress: scenarioData?.progress,
        hideChildren: scenarioData.hideChildren,
        color: scenarioData.color,
        count_items: scenarioData.count_items,
        opex: totalOpex,
        capex: totalCapex,
        totalexp: totalExpenditure,
        currency: symbol,
        noofRelation: scenarioData?.total_relations,
        relation_modulename: scenarioData?.relation_modulename,
      });

      scenarioData?.portfolio?.map((item) => {
        if (selectedPortfolio.includes(item.portfolio_id)) {
          totalOpex = 0;
          totalCapex = 0;
          totalExpenditure = 0;

          item.data.forEach((milestone) => {
            totalOpex += parseFloat(milestone?.opex);
            totalCapex += parseFloat(milestone?.capex);
            totalExpenditure += parseFloat(milestone?.totalexp);
            symbol = milestone?.currency;
          });

          const {
            type,
            portfolio_id,
            portfolioname: name,
            start,
            end,
            progress,
            project,
            color,
            count_items,
            data: dataItem,
            currency,
          } = item;

          const id = portfolio_id + "_" + scenarioData.id;
          dataFinal.push({
            type: "project",
            istask: "YES",
            textColor: color,
            id,
            name,
            start: new Date(start),
            end: new Date(end),
            color,
            count_items,
            progress,
            project,
            hideChildren: true,
            opex: totalOpex,
            capex: totalCapex,
            totalexp: totalExpenditure,
            currency: currency,
            noofRelation: scenarioData?.total_relations,
            relation_modulename: scenarioData?.relation_modulename,
          });

          if (dataItem.length) {
            dataItem.map((item1) => {
              const {
                type,
                item_id,
                displayname,
                start,
                end,
                progress,
                project,
                opex,
                capex,
                totalexp,
                expiry,
                relation_modulename,
                total_relations,
                currency,
              } = item1;

              const id1 = item_id + "_" + portfolio_id + "_" + scenarioData.id;

              //if (!dataFinal.some((item) => item.id === id1)) {

              dataFinal.push({
                type,
                id: id1,
                name: displayname,
                start: new Date(start),
                end: new Date(end),
                progress: id,
                project: id,
                opex: opex,
                capex: capex,
                totalexp: totalexp,
                //expiry: expiry ? new Date(expiry) : null,
                link:
                  `/portfolio/` +
                  relation_modulename +
                  `/` +
                  scenarioData?.id +
                  `/` +
                  item_id,
                currency: currency,
                noofRelation: total_relations,
                relation_modulename: relation_modulename,
              });
              //}
            });
          }
        }
      });

      // dataFinal.push({
      //   type: scenarioData.type,

      //   name: scenarioData.name,
      //   scenario_id: scenarioData.scenario_id,
      //   startTime: scenarioData.startTime,
      //   endTime: scenarioData.endTime,
      //   color: "",
      //   count_items: "0",
      // });

      // scenarioData?.portfolio?.map((item) => {
      //   if (selectedPortfolio.includes(item.portfolio_id)) {
      //     const { portfolioname, startTime, endTime, color, count_items } =
      //       item;
      //     const name = scenarioData.name + "-" + portfolioname;
      //     dataFinal.push({ name, startTime, endTime, color, count_items });
      //   }
      // });
    });

    //Data processing for bar chart
    const barChartData = [];

    console.log("dataFinal", dataFinal);

    dataFinal?.map((item) => {
      if (item?.type === "project" && !item?.istask) {
        const { name, totalexp } = item;
        const obj = {
          labels: [name],
          start: 0,
          end: totalexp,
          color: "#59A985",
        };
        barChartData.push(obj);
      }

      if (item?.type === "project" && item?.istask === "YES") {
        const { name, totalexp } = item;
        if (totalexp > 0) {
          const obj = {
            labels: [name],
            start: 0,
            end: totalexp,
            color: "#669ec4",
          };
          barChartData.push(obj);
        }
      }

      if (item?.type === "milestone") {
        const { name, totalexp } = item;
        if (totalexp > 0) {
          const obj = {
            labels: [name],
            start: 0,
            end: totalexp,
            color: "#f7a5f1",
          };
          barChartData.push(obj);
        }
      }
    });

    //end  Data Processing  for barchart
    setBarFinalChartData(barChartData);

    setFinalChartData(dataFinal);
  };

  const handleChange = (tags) => {
    setSelectedPortfolio(tags);
    sessionStorage.setItem(keySession2, JSON.stringify(tags))
  };

  const handleSwitchChange = (checked) => {
    setIsChecked(checked);
  };

  //console.log("object finalChartData", finalChartData);

  return (
    <React.Fragment>
      <LoadingBar color="#1f1f1f" ref={loadingBarRef} />
      <div className="container-fluid">
        <Layout>
          <Layout.Content className="business-capability-landscape-report">
            <section>
              <div className="headingbx">
                  <Flex justify='space-between' align='center' gap={4} className='w-100'>
                      <PageTitleHeading text={"Compare Scenario"} />
                      <Flex gap={8}>
                        <GoBack className={`ml-2 pull-right`} path={location?.state?.from} state={location?.state} back />
                      </Flex>
                  </Flex>
              </div>
            </section>
            <section>
              <Flex className="overflow-x pb-2">
                <Form
                  form={filterForm}
                  layout="inline"
                  style={{ maxWidth: "none" }}
                  className="mt-2"
                >
                  <Form.Item>
                    <Switch
                      checkedChildren="Timeline"
                      unCheckedChildren="Expenses"
                      defaultChecked
                      onChange={handleSwitchChange}
                      style={{
                        backgroundColor: isChecked ? "#52c41a" : "#ff4d4f",
                      }}
                    />
                  </Form.Item>

                  <Form.Item label="Select Portfolio">
                    <Select
                      {...selectDefaultProps}
                      mode="tags"
                      placeholder={"Select Portfolio"}
                      allowClear={true}
                      value={selectedPortfolio}
                      onChange={handleChange}
                    >
                      {portfolioList?.map((item) => {
                        return (
                          <Select.Option value={item.id}>
                            <span
                              className="portfolio-option-color"
                              style={{ "--color": item?.color }}
                            ></span>
                            {item.portfolioname}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Form>
              </Flex>
            </section>

            {/* <section>
              <Card className="mt-2">
                <Flex gap={8} className="overflow-x pb-2">
                  {(finalChartData && finalChartData.length && (
                    <CompareChart events={finalChartData} />
                  )) ||
                    "Data not found"}
                </Flex>
              </Card>
            </section> */}

            <section>
              {(isChecked && (
                <Card className="mt-2">
                  {(finalChartData && finalChartData.length && (
                    <CompareChart events={finalChartData} />
                  )) ||
                    "Data not found"}
                </Card>
              )) || (
                <Card className="mt-2">
                  {(finalChartData && finalChartData.length && (
                    <ExpenseChart events={finalBarChartData} />
                  )) ||
                    "Data not found"}
                </Card>
              )}
            </section>
          </Layout.Content>
        </Layout>
      </div>
    </React.Fragment>
  );
}

export default CompareScenarioV2;
