import React, { useEffect, useState } from 'react'
import ChnageLogsServices from '../../../services/services/ChangeLogsServices';
import { columns, getDateTime, renderContentData } from './ActivityFun';

const PDFTable = ({generatePDF,tableProps,totalRecords}) => {
    const [tableData,setTableData] = useState([]);

    useEffect(()=>{
        getRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const getRecords = async () =>{        
        try {
            generatePDF(true);
            let response = await ChnageLogsServices.getUserActivityLogs({
                ...tableProps,
                start:1,
                length: totalRecords,
            });
            setTableData(response?.data?.data);
        } catch (error) {
            console.error("error :",error)
        } finally {
            // generatePDF(false);
        }
    }

    return (
        <React.Fragment>
            <table className='table table-bordered' id="tableToExport">
                <thead>
                    <tr>
                        {
                            columns?.map(col=>{
                                return <th key={Math.random()}>
                                    {col?.title}
                                </th>
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        tableData?.map(tr=>{
                            return <tr key={Math.random()}>
                                {
                                    columns?.map(col=>{
                                        return <td key={Math.random()} style={{maxWidth:window?.innerWidth/columns?.length}}>
                                            {
                                                ((()=>{
                                                    if(col?.dataIndex === "createdAt" || col?.dataIndex === "updatedAt" ){
                                                        return getDateTime(tr[col?.dataIndex]);
                                                    }else{
                                                        return renderContentData(tr[col?.dataIndex],tr);
                                                    }   
                                                })())
                                            }
                                        </td>
                                    })
                                }
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </React.Fragment>
    )
}

export default PDFTable
