import React, { useState, useRef, useEffect } from "react";
import { RightCircleOutlined, SyncOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Dropdown, Flex, Popconfirm, Progress, Space, Spin, Switch, Tooltip, message, Modal } from "antd";
import ImportApplicationData from "./ImportApplicationData";
import ImportSvg from "./../../assets/import.svg";
import ExportSvg from "./../../assets/Export.svg";
import PdfFileSvg from "./../../assets/Pdf File.svg";
import ExcelFileSvg from "./../../assets/Excel File.svg";
import CsvFileSvg from "./../../assets/Csv File.svg";
import DataImportScenarioServices from "../../services/services/DataImportScenarioServices";
import { useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import PortfolioSettingServices from "../../services/services/PortfolioSettingsService";
import { render } from 'react-dom';
import ReactToPdf from 'react-to-pdf';
import axios from "axios";
import {
  addIsTextToProperties,
  baseUrl,
  deepFindByName,
  exportWitStatic,
  formatUrl,
  objectToQueryParams,
  transformText
} from "../../utils/Common";
import { jobStatusMessages, notificationType, placements, showNotification } from "../../utils/notificationUtil";
import { checkRole } from "../../helper/useUserData";

const ActionsBtn = ({exportWithFilter={},...props}) => {

  const items2 = [
    {
      key: "export_to_xlx_new",
      label: (
        <Button
          type="link"
          icon={<img src={ExcelFileSvg} alt={`XLS`} width={16} />}
          className="text-dark"
          block
          onClick={() => {
            exportExcelFileNew("xlsx");
          }}
        >
          XLS ALL
        </Button>
      ),
    },
    {
      key: "export_to_csv_new",
      label: (
        <Button
          type="link"
          icon={<img src={CsvFileSvg} alt={`CSV`} width={16} />}
          className="text-dark"
          block
          onClick={() => {
            exportExcelFileNew("csv");
          }}
        >
          CSV ALL
        </Button>
      ),
    },
  ];

  const userData = localStorage.getItem("userData");

  const [openImportModal, setOpenImportModal] = useState(false);
  const [openPDFModal, setOpenPDFModal] = useState(false);

  const [filter, setFilter] = useState(exportWithFilter || {});
  const [DDOption, SetDDOption] = useState(items2);
  
  let { moduleName } = useParams();
  const importExportStatusData = JSON.parse(localStorage.getItem("importExportStatus"));

  const [isImportInProgress, setIsImportInProgress] = useState(false); 
  const [isExportInProgress, setIsExportInProgress] = useState(false);
  const [exportAbortController, setExportAbortController] = useState(null);
  const [importAbortController, setImportAbortController] = useState(null);

  const [exportAuto, setExportAuto] = useState(false);
  const [exportPercent, setExportPercent] = useState(-50);
  const exportTimerRef = useRef(null)

  const [importAuto, setImportAuto] = useState(false);
  const [importPercent, setImportPercent] = useState(-50);
  const importTimerRef = useRef(null)


  useEffect(() => {
    // Relative URL from backend
    const relativeUrl =
      "/public/export/excel/Information%20Object_1722508858180_export.xlsx";

    // Construct the full URL
    const fileUrl = `${baseUrl}${formatUrl(relativeUrl)}`;
    // downloadFile(fileUrl);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const importExportStatusUpdated = JSON.parse(localStorage.getItem("importExportStatus"));
      if (importExportStatusUpdated?.length > 0) {
        try {
          const userInfo = JSON.parse(userData);
          const findExport = importExportStatusUpdated.find(
            (item) =>
              item?.tableName === moduleName &&
              item?.type === "export" &&
              item?.userEmail === userInfo?.email
          );
          setIsExportInProgress(findExport && findExport?.status === "started" || false);

          const findImport = importExportStatusUpdated.find(
            (item) =>
              item?.tableName === moduleName &&
              item?.type === "import" &&
              item?.userEmail === userInfo?.email
          );
          setIsImportInProgress(findImport && findImport?.status === "started" || false);

        } catch (error) {
          console.error("Error parsing userData:", error);
        }
      }else{
        setIsExportInProgress(false)
        setIsImportInProgress(false)
      }
    }, 3000); // 3000 milliseconds = 3 seconds

    // Cleanup interval on component unmount or when dependencies change
    return () => clearInterval(intervalId);
  }, [moduleName]);
  
  
  
  
   
  useEffect(()=>{

  },[openPDFModal])

  useEffect(() => {
    if (exportWithFilter && Object.keys(exportWithFilter)?.length > 0) {
      const { sortBy, limit, page, searchAllFields, searchFields, ...rest } =
        exportWithFilter;
      const newItems =
        searchFields && searchFields?.length > 1
          ? { searchFields, ...rest }
          : { ...rest };
      setFilter(exportWithFilter);
      if (Object.keys(newItems)?.length > 0) {
        SetDDOption([...items2 ]);
      } else {
        SetDDOption(items2);
      }
    }
  }, [exportWithFilter]);
  

  // Set auto-width for columns
  const autoWidth = async (ws) => {
    const colWidth = ws['!cols'] || [];
    const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
    data.forEach(row => {
      row.forEach((cell, i) => {
        const length = cell ? cell.toString().length : 10;
        colWidth[i] = { wch: Math.max(colWidth[i] ? colWidth[i].wch : 0, length) };
      });
    });
    ws['!cols'] = colWidth;
  };

  const exportExcelFileNew = async (typeFile='') => {
    const selectedFilters = localStorage.selectedFilters ? JSON.parse(localStorage.selectedFilters) : '';
    const { sortBy, limit, page, ...rest } = exportWithFilter;
    const isRestNotEmpty = Object.keys(rest).length > 0;

    const fileType = typeFile === 'csv' ? 'csv' : 'xlsx';
    const mimeType = fileType === 'csv' 
    ? 'text/csv;charset=utf-8;' 
    : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

    if (!isRestNotEmpty) {
      try {
        setIsExportInProgress(true);
        showNotification(
          notificationType.info,
          `${jobStatusMessages.started.message} for exporting ${moduleName}`,
          jobStatusMessages.started.description
        );
        const response = await DataImportScenarioServices.ExportPortfolio(moduleName, exportWithFilter);
        const blob = new Blob([response.data], {
          type: mimeType,
        });
  
        // Create an anchor element to trigger the download
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
  
        link.download = `${moduleName}_Data.${fileType}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        showNotification(
          notificationType.error,
          `${jobStatusMessages.failed.message}`,
          `${jobStatusMessages.failed.description}`,
        );
        console.error("Error downloading file:", error);
        message.error(error?.message);
      } finally {
        setIsExportInProgress(false);
        showNotification(
          notificationType.success,
          `${jobStatusMessages.completed.message} for ${moduleName}`,
          jobStatusMessages.completed.description,
          6
        );
      }
    } else {
      Modal.confirm({
        title: 'Are you sure you want to export the filtered records?',
        content: 'You have applied filters, and the export will only include the filtered data. If you want to export all records, please clear the filters and try again.',
        icon: <ExclamationCircleFilled />,
        async onOk() {
          try {
            setIsExportInProgress(true);
            showNotification(
              notificationType.info,
              `${jobStatusMessages.started.message} for exporting ${moduleName}`,
              jobStatusMessages.started.description
            );

            const response = await DataImportScenarioServices.ExportPortfolio(moduleName, selectedFilters);
            const blob = new Blob([response.data], {
              type: mimeType,
            });
  
            // Create an anchor element to trigger the download
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
  
            link.download = `${moduleName}_Data.${fileType}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } catch (error) {
            showNotification(
              notificationType.error,
              `${jobStatusMessages.failed.message}`,
              `${jobStatusMessages.failed.description}`,
            );
            console.error("Error downloading file:", error);
            message.error(error?.message);
          } finally {
            setIsExportInProgress(false);
            showNotification(
              notificationType.success,
              `${jobStatusMessages.completed.message} for ${moduleName}`,
              jobStatusMessages.completed.description,
              6
            );
          }
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }
  };
  

  return (
    <>
      <Space nowrap={"true"} direction="horizontal">
        {props?.showImport && checkRole('import') &&!isImportInProgress && (
          <Tooltip title={`Import`}>
            <Button
              className=""
              icon={<img src={ImportSvg} alt={`Import`} width={25} />}
              onClick={() => {
                setOpenImportModal(!openImportModal);
              }}
            />
          </Tooltip>
        )}
        {isImportInProgress && (
          <Tooltip title={`Import in progress will update you after complete.`}>
            <Button icon={<SyncOutlined style={{ color: "#2A609D" }} width={25} spin />} className=""/>
          </Tooltip>
        )}
        {props?.showExport && checkRole('export') && !isExportInProgress && (
          <Dropdown menu={{ items: DDOption }} placement="bottom">
            <Tooltip title={`Export`}>
              <Button
                icon={<img src={ExportSvg} alt={`Export`} width={25} />}
              />
            </Tooltip>
          </Dropdown>
        )}
        {isExportInProgress && (
          <Tooltip title={`Export in progress will update you after complete.`}>
            <Button icon={<SyncOutlined style={{ color: "#2A609D" }} width={25} spin />} className="pt-2"/>
          </Tooltip>
        )}
      </Space>
      <ImportApplicationData
        open={openImportModal}
        setOpen={setOpenImportModal}
        setOpenPDF={setOpenPDFModal}
        openPDF={openPDFModal}
        setIsImportInProgress={setIsImportInProgress}
        isImportInProgress={isImportInProgress}
        {...props}
      />
    </>
  );
};

ActionsBtn.props = {
  showImport:true,
  showExport:true,
  exportWithFilter:{},
}

export default ActionsBtn;
