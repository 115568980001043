import React, { useEffect, useRef } from 'react';
import mxgraph from 'mxgraph';

const { mxGraph, mxUtils, mxCodec } = mxgraph();

const GraphComponent = ({ xmlString ,width= '100%',height= '100%',style={}}) => {
    const containerRef = useRef(null);

    useEffect(() => {
        const graph = new mxGraph(containerRef.current);
        const xmlDoc = mxUtils.parseXml(xmlString);
        const node = xmlDoc.documentElement;
        const codec = new mxCodec(node.ownerDocument);
        
        // Decode the XML into the graph model
        codec.decode(node, graph.getModel());

        // Optionally, set some styles or configurations
        graph.setConnectable(true);
        graph.setPanning(true);
        graph.setTooltips(true);
        
        // Refresh the graph display
        graph.refresh();

        return () => {
            // Cleanup if necessary
        };
    }, [xmlString]);

    return <div ref={containerRef} style={{ width,height,...style }} />;
};

export default GraphComponent;