import { Button, Col, Row, message, Form } from "antd";
import React, { useEffect, useState } from "react";
import CustomDiagramModal from "../../../custom/CustomDiagramModal";
import diagramServices from "../../../../services/services/diagramServices";
import { useAppndiagram } from "../../../../Context/diagramProvider";
import { useLocation, useNavigate } from "react-router";
import { PlusOutlined } from "@ant-design/icons";
import { snakeToPascal } from "../../../../utils/Common";
import  ScenarionServices  from '../../../../services/services/ScenarioServices'


const AddDiagram = ({
  businessApplicationId,
  moduleName,
  onListUpdate,
  titleName,
  editDiagramTitle,
  setEditDiagramTitle,
  portfolioSetting,
  scenarioId,
  refreshTab,
  setrefreshTab
}) => {
  const { state, dispatch } = useAppndiagram();
  const [visibleModal, setVisibleModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formModalData, setFormModalData] = useState();

  const formModalDataTemp = {
    name: titleName,
    description: "",
    moduleID: businessApplicationId || null,
    moduleName: moduleName,
  };

  let navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // console.log("formModalData", formModalData);
  }, [formModalData]);

  useEffect(() => {
    if (editDiagramTitle) {
      setVisibleModal(true);
      setFormModalData(editDiagramTitle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editDiagramTitle]);

  const handleCreate = async ({ formData: values, openEditor }) => {
    try {
      values.moduleID = businessApplicationId;
      if(values?.moduleName === "ObjectiveOkr"){
        values.moduleName = "ObjectivesOkr"
      }

      // Handle form submission
      setLoading(true);
      let response = null;
      let newData = null;
      if (values?.id > 0) {
        response = await diagramServices.updatediagram(values);
        newData = response?.data || null;

        await dispatch({ type: "UPDATE_DIAGRAM", payload: newData });
 
        // Handle success response
        message.success("Updated Successfully.");
        setrefreshTab(!refreshTab)
      } else {
        response = await diagramServices.creatediagram(values);
        newData = response?.data || null;

        await dispatch({ type: "ADD_LINK", payload: newData });
        // Handle success response
        message.success("Created Successfully.");
      }
	  
		if (scenarioId) {
		  let modifiedModuleName = moduleName;
		  if (moduleName === 'BusinessProcess') {
			modifiedModuleName = 'BusinessProcesses';
		  }
		  if (moduleName === 'ApqcsProcess') {
			modifiedModuleName = 'ApqcsProcesses';
		  }
		
		  const modulenameScenario = portfolioSetting?.find(
			(item) => snakeToPascal(item?.moduleName) === modifiedModuleName
		  );

		  const objdata = {
			source_id: businessApplicationId,
			modulename: modulenameScenario?.moduleName,
			tab_name: "Diagram",
		  };

		  const obj = await ScenarionServices.checkscenariomodifyrecord(
			objdata
		  );
		}

      setVisibleModal(false);
      setTimeout(() => {
        setLoading(false);
        if (
          openEditor &&
          !values?.id &&
          values?.diagram_type === "diagram_editor"
        ) {
          return navigate(`/diagram-editor/${newData?.id}`, {
            state: {
              previousPath: location?.pathname,
              titleName: titleName,
              moduleName: moduleName,
            },
          });
        }
        onListUpdate();
      }, 1000);
    } catch (error) {
      // Handle error
      message.error("Failed to submit form. Please try again.");
      setLoading(false);
    } finally {
      setLoading(false);
      setVisibleModal(false);
    }
  };

  const handleCancel = () => {
    setVisibleModal(false);
    setEditDiagramTitle(null);
    setLoading(false);
  };

  return (
    <div className="mt-4">
      <Row gutter={16} className="mb-2">
        <Col span={24}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setVisibleModal(!visibleModal);
              setFormModalData(formModalDataTemp);
              setEditDiagramTitle(null);
            }}
          >
            Diagram
          </Button>
        </Col>
      </Row>

      {visibleModal && (
        <CustomDiagramModal
          visible={visibleModal}
          onCancel={handleCancel}
          onOk={handleCreate}
          okText="Save"
          okText2={editDiagramTitle?.id ? "Save" : "Save and Open Editor"}
          cancelText="Cancel"
          closable={false}
          loading={loading}
          setLoading={setLoading}
          initialValues={formModalData}
        />
      )}
    </div>
  );
};

export default AddDiagram;
