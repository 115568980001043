import { Button, Drawer } from 'antd';
import React, { useState } from 'react'
import GraphComponent from '../../../utils/GraphComponent';

const GraphContainer = ({title,xmlString}) => {
    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    }; 

    return (
        <React.Fragment>
            <Button type="default" onClick={showDrawer}> Graph </Button>
            <Drawer
                title={title}
                placement="top"
                onClose={onClose}
                open={open}
                height={window?.innerHeight}
                destroyOnClose
            >
                <GraphComponent xmlString={xmlString} style={{pointer:`none`}}/>
            </Drawer>
        </React.Fragment>
    )
}

export default GraphContainer
