import "./ApplicationList.scss";
import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { message, Modal, Button, Upload, Select, Form, Spin, Typography } from "antd";
import * as XLSX from "xlsx";
 
import DataImportScenarioServices from "../../services/services/DataImportScenarioServices";
import {
  addIsTextToProperties,
  deepFindByName,
  exportWitStatic,
  objectToQueryParams,
  portfolioSortingValues,
  statusColors,
} from "../../utils/Common";
import PortfolioSettingServices from "../../services/services/PortfolioSettingsService";
import {
  setMenuPortfolio,
} from "../../utils/settingCommon";
import { cleanData } from "jquery";
import { LoadingOutlined } from "@ant-design/icons";
import BpmnServices from "../../services/services/BpmnServices";

const { Text } = Typography;

const ImportApplicationData = ({isDiagram=false,...props}) => {
  const [form] = Form.useForm();
  const [messageApi] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [uploadedData, setUploadedData] = useState([]);
  const [formModalData, setFormModalData] = useState({});
  const [openPreDefinedFormModal, setOpenPreDefinedFormModal] = useState(false);
  const [previewDataModal, setPreviewDataModal] = useState(false);
  const [openOwnFormatFormModal, setOpenOwnFormatFormModal] = useState(false);
  const [errorCells, setErrorCells] = useState([[2, 1]]);
  const [openOwnFormatFormMappingModal, setOpenOwnFormatFormMappingModal] =
    useState(false);
  const [errorData, setErrorData] = useState([]);

  const [model, setModel] = useState("");
  const [view, setView] = useState("");

  const [formItems, setFormItems] = useState([]);
  const [portfolioData, setPortfolioData] = useState({});
  let { moduleName } = useParams();
  const [templateData, setTemplateData] = useState([]);
  const [openUploadProcessModel, setOpenUploadProcessModel] = useState(false);
  const [preInsertedItems, setPreInsertedItems] = useState([]);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  //New states
  const [excelJsonData, setExcelJsonData] = useState([]);
  const [updatedItems, setUpdatedItems] = useState([]);
  const [directImport, setDirectImport] = useState(false);
  const previousUpdatedItemsRef = useRef(0);
  const uploadRef = useRef(null);
  const importButtonRef = useRef(null);
  const [isImportButtonDisabled, setImportButtonDisabled] = useState(false);
  const [isFinishImportButton, setImportButton] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [list, setList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);

  //Get all settings
  const fetchAllPortfolio = async () => {
    try {
      const response = await PortfolioSettingServices.getList({
        page: 1,
        limit: 200,
      });
      setPortfolioData(response);
      setMenuPortfolio(response?.data?.data);
    } catch (error) {
      console.error("Error fetching portfolio:", error);
      message.error("Something went wrong.");
    }
  };

  useEffect(() => {
   if (uploadedData) {
    setUpdatedItems(uploadedData);
   }
  }, [uploadedData]);


  useEffect(() => {
    if (moduleName == "BusinessApplication") {
      setModel("Application");
      setView("List");
    } else if (moduleName == "BusinessCapability") {
      setModel("BusinessCapability");
      setView("Grid");
    } else if (moduleName == "BusinessProcesses") {
      setModel("Business Process");
      setView("Grid");
    }
  }, []);

  useEffect(() => {
    fetchAllPortfolio();
  }, []);

  useEffect(() => { 
  }, [formItems]);

  useEffect(() => {
    const moduleNameNew = portfolioData?.data?.data?.find(
      (item) => transformText(item.moduleName, "pascal") == moduleName
    ); 
 
    if (portfolioData?.data?.data?.length) {
      const portfolioValues = deepFindByName(
        portfolioData?.data?.data,
        moduleNameNew?.name,
        false
      );

      const formItem = [];
      portfolioValues?.portfolioSections.map((section, index) => {
        section?.properties?.length > 0 &&
          section?.properties?.map((property, index) => {
            formItem.push(property);
          });
      });
      setFormItems(formItem);
    }
  }, [portfolioData?.data?.data]);

  const transformText = (text, caseType) => {
    switch (caseType) {
      case "snake":
        return text.toLowerCase().replace(/\s+/g, "_");
      case "camel":
        return text
          .toLowerCase()
          .replace(/[^a-zA-Z0-9]+(.)/g, (match, chr) => chr.toUpperCase());
      case "kebab":
        return text.toLowerCase().replace(/\s+/g, "-");
      case "pascal":
        return (" " + text)
          .toLowerCase()
          .replace(/[^a-zA-Z0-9]+(.)/g, function (match, chr) {
            return chr.toUpperCase();
          });
      default:
        return text;
    }
  };

  const downloadExcelTemplate = async () => {
    try {
      const response = await DataImportScenarioServices.ExportPortfolio(moduleName, 'Blank Template');
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create an anchor element to trigger the download
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);

      link.download = `${moduleName}_Data.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error fetching Template Data:", error);
      message.error("Something went wrong.");
    }
  };

  const uploadFile = {
    name: "file",
    multiple: false,
    maxCount: 1,
    accept:
      "xls,xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    beforeUpload: () => {
      return false;
    },
    onChange(info) {
      const { status } = info.file;
      if (status === 'done') {
        setIsFileUploaded(true);
      } else if (status === 'error' || status === 'removed') {
        setIsFileUploaded(false);
      }
    },

    onDrop(e) { 

    },
  };

  const onFinish = (formData) => {
    setLoading(true);
    setFormModalData(formData);
    setImportButton(false);
    const file = formData?.upload_file?.file;
    const reader = new FileReader();

    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet,{
        defval: "",
      });

      const updatedData = jsonData.map(row => {
        const updatedRow = {};
        Object.keys(row).forEach(key => {
          if (typeof row[key] === 'string') {
            updatedRow[key] = row[key].replace(/'/g, "''");
          } else if (typeof row[key] === 'number') {
            if (key.toLowerCase().includes('date')) {
              const date = XLSX.SSF.parse_date_code(row[key]);
              const day = String(date.d).padStart(2, '0');
              const month = String(date.m).padStart(2, '0');
              const year = date.y;
              updatedRow[key] = `${year}-${month}-${day}`;
            } else {
              updatedRow[key] = row[key];
            }
          } else {
            updatedRow[key] = row[key];
          }
        });
        return updatedRow;
      });
      if (updatedData.length === 0) {
        setLoading(false);
        message.error("No data found in the file. Please upload a valid file.", 6);
        return;
      }
      const excelData = JSON.stringify(updatedData);

      const userID = localStorage.getItem("email") || "";
      const dataSend = {moduleName, excelData, userID};
      const response = await DataImportScenarioServices.importPortfolioRecords(dataSend);
      if(response?.data?.errors || directImport === false) {
        setErrorData([]);
        if(response?.data?.errors) {
          await setErrorData(response?.data?.errors);
          setImportButton(true);
        }
        setUploadedData(updatedData);
        setPreviewDataModal(!previewDataModal);
      }
      else {
        setOpenPreDefinedFormModal(!openPreDefinedFormModal);
        setUploadedData([]);
        setErrorData([]);
        if (isImportButtonDisabled) setPreviewDataModal(!previewDataModal);
        setImportButtonDisabled(false);
        setImportButton(false);
        message.success("Uploaded successfully.", 6);
        setTimeout(() => {
            const buttonClickAfterImport = document.getElementById("buttonClickAfterImport");
            if (buttonClickAfterImport) {
              buttonClickAfterImport.click();
            }
        }, 500); 
      }

      setLoading(false);
    };
    reader.readAsArrayBuffer(file);
  };

  const normalizeFileName = (fileName) => {
    // Remove anything in parentheses, and trim leading/trailing whitespace
    return fileName?.replace(/\s*\(.*?\)\s*/, '').trim();
  };

  const onFinishDiagram = async (formData)=>{
    try {
      setLoading(true);

      const filename = formData?.upload_file?.file?.name;
      // Normalizing both filenames for comparison
      const normalizedFilename = normalizeFileName(filename);
      const normalizedDiagramFileName = normalizeFileName(props?.diagramFileName);

      if (normalizedFilename !== normalizedDiagramFileName) {
        setLoading(false);
        message.error(`Invalid file name  file name should be ${props?.diagramFileName} please check your file.`);
        return;
      }

      if (!props?.diagramId) {
        setLoading(false);
        message.error("Diagram not found.");
        return;
      }

      let postData = {};
      postData.image = formData?.upload_file?.file;
      postData.diagramId = props?.diagramId;
      const response = await BpmnServices.SaveImportDiagram(postData);
      if (response?.data?.code === 200) {
        message.success(response?.data?.message,6);
        setLoading(false);
        setOpenPreDefinedFormModal(!openPreDefinedFormModal);
        setOpenUploadProcessModel(!openUploadProcessModel);
        setUpdatedItems([]);
        setPreInsertedItems([]);
        setExcelJsonData([]);
        setDirectImport(false);
        setTimeout(() => {
          props?.showDiagramData(props?.diagramId);
        }, 500);
      }else{
        message.error(response?.data?.message,6);
        setLoading(false);
      }
      
    } catch (err) {
      setLoading(false);
      message.error(err?.response?.data?.message || err?.message,6);
    }
  }

  const TableBody = ({ updatedItems = [] }) => {
      return (
        <>
          {/* Fallback Header Row */}
          {uploadedData.length > 0 && (
            <tr>
              {Object.keys(uploadedData[0]).map((key) => (
                <th key={key}>{key}</th>
              ))}
            </tr>
          )}
          {/* Display Data Rows */}
          {uploadedData.length > 0 ? (
            uploadedData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {Object.keys(row).map((key) => (
                  <td key={key}>{row[key]}</td>
                ))}
              </tr>
            ))
          ) : (
            <tr className="mt-2">
              <td
                colSpan={Object.keys(uploadedData[0] || {}).length}
                style={{ borderColor: "gray", color: "red" }}
              >
                No records found.
              </td>
            </tr>
          )}
        </>
      );
  };
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const onValuesChange = (changedValues, allValues) => {
    if (allValues.upload_file) {
      setIsFileUploaded(allValues.upload_file.fileList.length > 0);
    } else {
      setIsFileUploaded(false);
    }
  };

  const handleCancel = () => {
    form.resetFields(); 
  };

  const finishImportButton = () => {
    setImportButtonDisabled(true);
    if (importButtonRef.current) {
      importButtonRef.current.click();
    }
  };

  return (
    <>
      <Modal
        open={props?.open}
        title={`Import ${props?.name}`}
        onCancel={() => {
          handleCancel();

          props.setOpen(false);
        }}
        okButtonProps={{ style: { backgroundColor: "#9ba894 !important" } }}
        footer={false}
        width={`400px`}
        maskClosable={false}
      >
        <div className="text-center mt-5">
          <Button
            type={`primary`}
            onClick={async () => {
              // await getPreInsertedData(moduleName);
              form.resetFields();
              setLoading(false);
              setOpenPreDefinedFormModal(!openPreDefinedFormModal);
              setUploadedData([]);
              setPreInsertedItems([]);
              setExcelJsonData([]);
              setUpdatedItems([]);
              setIsFileUploaded(false);
              setDirectImport(false);
              previousUpdatedItemsRef.current = 0;
              setOpenUploadProcessModel(false);
              props.setOpen(false);
            }}
          >
            Import Pre-Defined File Format
          </Button>
        </div>
      </Modal>

      <Modal
        open={openPreDefinedFormModal}
        title={`Import Pre-Defined File Format`}
        footer={false}
        onCancel={() => {
          handleCancel();
          setOpenPreDefinedFormModal(!openPreDefinedFormModal);
        }}
        maskClosable={false}
      >
        <Form
          layout="vertical"
          onFinish={!isDiagram ? onFinish : onFinishDiagram}
          form={form}
          onValuesChange={onValuesChange}
        >
          <Form.Item
            name="upload_file"
            rules={[{ required: true, message: "Please Upload Excel File" }]}
          >
            <Upload.Dragger {...uploadFile} ref={uploadRef}>
              <p className="ant-upload-text text-center">
                Drag and Drop Your File
              </p>
              <p className=" text-center my-2">Or</p>
              <p className="btn btn-outline-primary text-center">Choose File</p>
            </Upload.Dragger>
          </Form.Item>
          {!isDiagram ? (
            <>
              <div className="text-center my-2">
                <div className="flex items-center justify-center gap-2">
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="text-center"
                    onClick={() => {
                      // setPreviewDataModal(!previewDataModal);
                      setDirectImport(false);
                      props.setOpen(false);
                      setOpenUploadProcessModel(false);
                    }}
                    disabled={!isFileUploaded || loading}
                  >
                    Preview
                  </Button>

                  <Button
                    ref={importButtonRef}
                    type="primary"
                    htmlType="submit"
                    className="text-center"
                    disabled={
                      !isFileUploaded || loading || openUploadProcessModel
                    }
                    style={{
                      backgroundColor:
                        !isFileUploaded || loading || openUploadProcessModel
                          ? "#f5f5f5"
                          : "#52c41a", // Light gray for disabled, green for enabled
                      borderColor:
                        !isFileUploaded || loading || openUploadProcessModel
                          ? "#dddddd"
                          : "#52c41a",
                    }}
                    onClick={() => {
                      setDirectImport(true);
                    }}
                  >
                    {openUploadProcessModel ? <Spin /> : "Import"}
                  </Button>
                </div>
                {loading && (
                  <div style={{ marginTop: 10 }}>
                    <Spin indicator={antIcon} />
                    <span style={{ marginLeft: 10 }}>
                      {"Fetching your details..."}
                    </span>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="text-center my-2">
                <div className="flex items-center justify-center gap-2">
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="text-center"
                    onClick={() => {
                      props.setOpen(false);
                      setOpenUploadProcessModel(false);
                    }}
                    disabled={!isFileUploaded || loading}
                  >
                  {loading ? <Spin /> :  `Import ${props.name}`}
                  </Button>
                </div>
              </div>
            </>
          )}
        </Form>
        {!isDiagram ? (
          <>
            <p className="mb-0">Don't have import file format?</p>
            <Button
              type="text"
              onClick={() => {
                downloadExcelTemplate();
              }}
              className="text-primary"
            >
              Click here to download sample file
            </Button>
          </>
        ) : (
          <></>
        )}
      </Modal>

      {/* Preview Data Model  */}
      <Modal
        open={previewDataModal}
        title={`${model} Import Preview`}
        footer={false}
        onCancel={() => {
          setPreviewDataModal(!previewDataModal);
        }}
        okButtonProps={{ style: { backgroundColor: "#9ba894 !important" } }}
        width={"80%"}
        maskClosable={false}
      >
        <div
          className="table-responsive mt-4"
          style={{ height: 300, overflow: "scroll" }}
        >
          <table
            width={`100%`}
            className="table table-sm cu-table-bordered excel-list "
            cellSpacing={1}
          >
            {<TableBody updatedItems={uploadedData || []} />}
          </table>
        </div>
        <div className="d-flex justify-content-between">
          <div>
            {errorData.length > 0 && (
              <>
                <h5>
                  <span className="text-danger">
                    Error: {errorData.length} error(s) found
                  </span>
                </h5>
                <span className="text-danger">
                  Clear the errors in the Excel file and upload again.
                </span>
              </>
            )}
            <div
              className="table-responsive mt-4"
              style={{ height: 100, overflow: "auto" }}
            >
              <table
                width={`100%`}
                className="table table-sm cu-table-bordered excel-list"
                cellSpacing={1}
              >
                {errorData?.map((trData, trIndex) => {
                  return (
                    <tr key={Math.random()}>
                      {Object.keys(trData)?.map((tdData, tdIndex) => {
                        {
                          if (trIndex === 0) {
                            return <th key={Math.random()}>{tdData}</th>;
                          }
                        }
                      })}
                    </tr>
                  );
                })}

                {errorData?.map((trData, trIndex) => {
                  return (
                    <tr key={Math.random()}>
                      {Object.keys(trData)?.map((tdData, tdIndex) => {
                        {
                          if (trIndex === 0) {
                            return (
                              <td key={Math.random()}>{trData[tdData]}</td>
                            );
                          } else {
                            return (
                              <td key={Math.random()}>{trData[tdData]}</td>
                            );
                          }
                        }
                      })}
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
          <div style={{ marginTop: "10px" }}>
            {!(openUploadProcessModel || isImportButtonDisabled || isFinishImportButton) && (
              <Button onClick={finishImportButton}>
                {isImportButtonDisabled || openUploadProcessModel ? <Spin /> : "Finish Import"}
              </Button>
            )}
          </div>
        </div>
         {/*
        <div className="d-flex justify-content-between">
          <div>
            <Text className="text-info">
              <Text strong>Note:</Text> New or modified relationship changes
              will not be shown here.
            </Text>
          </div>
        </div> */}
      </Modal>
    </>
  );
};

export default ImportApplicationData;
