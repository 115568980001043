import { encodeQueryData } from '../../utils/Common';
import http from './httpService';
const API_BASE_URL = process.env.REACT_APP_API_URL


async function getChangeLogsList({ moduleName, modelId }) {
    
     const url = `${API_BASE_URL}changelogs?module_name=${moduleName}&modelID=${modelId}`;
  
    let response = await http.get(url);
    return response;
}

async function createChangeLogs(data) {
    const url = `${API_BASE_URL}changelogs`;
    let response = await http.post(url, data);
    
    return response;
}

const getUserActivityLogs =async (props) => {
    const url = `${API_BASE_URL}changelogs/user-activity-logs`;
   let response = await http.post(url,props);
   return response;
}

const getUsers =async (params) => {
    const url = `${API_BASE_URL}user/list${params ? `?${encodeQueryData(params)}` : ''}`;
   let response = await http.get(url);
   return response;
}

const ChnageLogsServices = {
    getUsers,
    getUserActivityLogs,
    getChangeLogsList,
    createChangeLogs,
}


export default ChnageLogsServices;
